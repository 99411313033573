import React, { useState } from "react";
import _default from "react-bootstrap/esm/CardGroup";
import moment from "moment";
//import { metaPixelService } from "../../services/metaPixel.service";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

export function BoxListAudience(props) {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className=" content_card_audience">
      <h4 className="text-muted text fnt_medium mb-1">{props.Title}</h4>
      <div className="box_list_audience inAudience">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Próximamente
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              Temporadas
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="row">
              {props.Items &&
                props.Items.sort((a, b) =>
                  a.data.conferenceDate > b.data.conferenceDate ? 1 : -1
                ).map((item) => (
                  <div className="col-sm-6 col-lg-4 mb-4">
                    <a
                      className="item_audience shadows card"
                      href={item.data.conferenceUrl}
                      onClick={() => {
                        var data = {
                          content_name: item.data.title,
                          content_type: "product",
                          contents: [
                            {
                              id: item.data.auditoriumId,
                              quantity: 1,
                              link: item.data.conferenceUrl,
                            },
                          ],
                        };
                        // metaPixelService.trackEvent(
                        //   "track",
                        //   "ViewContent",
                        //   data
                        // );
                      }}
                      target={_default}
                    >
                      <div className="box_content_img">
                        {moment(item.data.conferenceDate).isBefore(
                          new Date(),
                          "minutes"
                        ) && (
                          <span class="dots_notif">
                            <svg height="12" width="12" class="blinking">
                              <circle cx="6" cy="6" r="4" fill="red" />.
                            </svg>{" "}
                            En Vivo
                          </span>
                        )}
                        <img
                          src={item.files.length > 0 && item.files[0].path}
                          alt={item.data.title}
                        />
                      </div>
                      <h5 className="mb-0 text fnt_bold ">{item.data.title}</h5>
                      <p className="paragraph myp">
                        {moment(item.data.conferenceDate).format("LLLL")}
                      </p>
                      <p className="paragraph text-muted m-0">
                        <small>Conferencias</small>
                      </p>
                    </a>
                  </div>
                ))}
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="row">
              {props.OldItems.length > 0 &&
                props.OldItems.sort((a, b) =>
                  a.data.conferenceDate < b.data.conferenceDate ? 1 : -1
                ).map((item) => (
                  <div className="col-sm-6 col-lg-4 mb-4">
                    <a
                      className="item_audience shadows card"
                      href={item.data.conferenceUrl}
                      onClick={() => {
                        var data = {
                          content_name: item.data.title,
                          content_type: "product",
                          contents: [
                            {
                              id: item.data.auditoriumId,
                              quantity: 1,
                              link: item.data.conferenceUrl,
                            },
                          ],
                        };
                        // metaPixelService.trackEvent(
                        //   "track",
                        //   "ViewContent",
                        //   data
                        // );
                      }}
                      target={_default}
                    >
                      <div className="box_content_img">
                        <img
                          src={item.files.length > 0 && item.files[0].path}
                          alt={item.data.title}
                        />
                      </div>
                      <h5 className="mb-0 text fnt_bold ">{item.data.title}</h5>
                      <p className="paragraph myp">
                        {moment(item.data.conferenceDate).format("LLLL")}
                      </p>
                      <p className="paragraph text-muted m-0">
                        <small>Conferencias</small>
                      </p>
                    </a>
                  </div>
                ))}
            </div>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
}
