import React, { useEffect, useState, useRef } from "react";
import { useSurveyUserProfileAnswer } from "../../services/surveyUserProfileAnswer.service";
import { useSurvey } from "../../services/survey.service";

import swal from "sweetalert";
import { array } from "yup";

export function AnswersSurvey({
  index,
  question,
  surveyUserProfileId,
  next,
  previous,
  setQuestionIndexSave,
  questionIndexSave,
  setLastSave,
  questionIndex,
  questionCount,
  setPreviousClick,
  previousClick,
  getQuestionIndex,
}) {
  const [userAnswer, setUserAnswer] = useState({});
  const [answerData, setAnswersData] = useState([]);
  const [answerScale, setAnswerScale] = useState([]);
  const [selectedAnswerId, setSelectedAnswerId] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [localAttachments, setLocalAttachments] = useState([]);
  const [radioValues, setRadioValues] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [skipBack, setSkipBack] = useState(false);
  const maxSize = 10;
  const myRefs = useRef([]);

  useEffect(() => {
    if (question.surveyAnswerTypeId == 3) {
      useSurvey
        .getSurveyScaleAnswerById(question.surveyScaleId)
        .then((result) => {
          if (result) {
            setAnswerScale(result);
          }
        });
    }
    getAnswers(question);
  }, [question]);

  useEffect(() => {
    if (index == questionIndex - 1) {
      getAnswers(question);
      const timer = setTimeout(() => {
        if (question.surveyAnswerTypeId == 3) {
          useSurveyUserProfileAnswer
            .getSurveysUserProfileAnswersListByQuestionId(
              question.surveyQuestionId,
              surveyUserProfileId
            )
            .then((result) => {
              if (result) {
                setSelectedAnswers(result);
              }
            });
        }
      }, 432);
      return () => clearTimeout(timer);
    }
  }, [questionIndex]);

  useEffect(() => {
    var filterData = answerData.filter(
      (item) => item.surveyAnswerConfigId == selectedAnswerId
    );
    if (filterData.length > 0) {
      if (!filterData[0].active && previousClick) {
        setTimeout(() => {
          previous(question.surveyQuestionId);
        }, 300);
      }
      if (filterData[0].active && previousClick) {
        setPreviousClick(false);
      }
    }
  }, [userAnswer]);

  useEffect(() => {
    var obj = {};
    answerData.map((x) => {
      if (x.active) {
        if (!radioValues[x.surveyAnswerConfigId] || radioValues.length == 0)
          obj[x.surveyAnswerConfigId] = 0;
      }
    });
    setRadioValues(obj);
  }, [answerData]);

  useEffect(() => {
    answerData.map((x) => {
      var filterData = selectedAnswers.filter(
        (item) => item.selectedAnswerConfigId == x.surveyAnswerConfigId
      );
      if (filterData.length > 0) {
        if (x.active) {
          radioValues[filterData[0].selectedAnswerConfigId] = parseInt(
            filterData[0].value
          );
          var reference =
            "ans" + filterData[0].value + "id" + x.surveyAnswerConfigId;
          var el = myRefs.current[reference];
          el.checked = true;
        }
      }
    });
  }, [selectedAnswers]);

  useEffect(() => {
    if (questionIndexSave == index) handleSubmit();
  }, [questionIndexSave]);

  const getAnswers = async (question) => {
    await useSurveyUserProfileAnswer
      .getSurveysUserProfileAnswersByQuestionId(
        question.surveyQuestionId,
        surveyUserProfileId
      )
      .then((result) => {
        if (result) {
          setAnswersData(result.answers);
          if (result.selectedAnswerId) {
            setSelectedAnswerId(result.selectedAnswerId);
            var ua = {
              surveyUserProfileAnswerId: result.surveyUserProfileAnswerId,
              surveyUserProfileId: surveyUserProfileId,
              surveyQuestionId: question.surveyQuestionId,
              selectedAnswerConfigId: result.selectedAnswerId,
              value: result.answerValue,
            };
            setUserAnswer(ua);
            if (!previousClick) {
              setTimeout(() => {
                setLastSave(index);
              }, index * 250);
            }
          } else {
            var ua = {
              surveyUserProfileAnswerId: 0,
              surveyUserProfileId: 0,
              surveyQuestionId: question.surveyQuestionId,
              selectedAnswerConfigId: 0,
              value: "",
            };
            setUserAnswer(ua);
          }
          if (result.answerValue) {
            setSelectedValue(result.answerValue);
          }
        }
      });
  };

  const handleTextChange = (event, answer) => {
    event.persist();
    setSelectedAnswerId(answer.surveyAnswerConfigId);
    setSelectedValue(event.target.value);
  };

  const tabLock = (e) => {
    if (e.key === 'Tab' || e.key === 'Alt'){
      e.preventDefault() 
    }
  };

  const handleRadioChange = (event, answer, choice) => {
    radioValues[answer.surveyAnswerConfigId] = choice;
    setRadioValues(radioValues);
    event.persist();
    setSelectedAnswerId(answer.surveyAnswerConfigId);
    setSelectedValue(event.target.value);
    addSingleAswerScale(answer.surveyAnswerConfigId,choice);
  };
  const handleOptionChange = (event, answer) => {
    event.persist();
    setSelectedAnswerId(answer.surveyAnswerConfigId);
  };

  const handleSubmit = () => {
    if (question.answerType == "Multimedia") {
      multimediaAswer();
      return;
    }
    if (question.surveyAnswerTypeId == 3) {
      scaleAnswer();
      return;
    }
    if (userAnswer.surveyUserProfileAnswerId) {
      var upd = {
        surveyUserProfileAnswerId: userAnswer.surveyUserProfileAnswerId,
        surveyUserProfileId: surveyUserProfileId,
        surveyQuestionId: userAnswer.surveyQuestionId,
        selectedAnswerConfigId: selectedAnswerId,
        value: selectedValue,
      };
      useSurveyUserProfileAnswer
        .updateSurveyUserProfileAnswer(upd)
        .then((result) => {
          if (result != null) {
            if (result.isSurveyCompleted) {
              swal({
                text: "La encuesta se ha completado",
                icon: "success",
              }).then(() => window.location.reload());
            } else {
              setUserAnswer(result.userAnswer);
              nextStep();
            }
          } else setQuestionIndexSave(-1);
        })
        .catch((error) => setQuestionIndexSave(-1));
    } else {
      var ins = {
        surveyUserProfileId: surveyUserProfileId,
        surveyQuestionId: question.surveyQuestionId,
        selectedAnswerConfigId: selectedAnswerId,
        value: selectedValue,
      };
      useSurveyUserProfileAnswer
        .createSurveyUserProfileAnswer(ins)
        .then((result) => {
          if (result != null) {
            if (result.isSurveyCompleted) {
              swal({
                text: "La encuesta se ha completado",
                icon: "success",
              }).then(() => window.location.reload());
            } else {
              setUserAnswer(result.userAnswer);
              nextStep();
            }
          } else setQuestionIndexSave(-1);
        })
        .catch((error) => setQuestionIndexSave(-1));
    }
  };

  const nextStep = () => {
    var step = 0;
    var filterData = answerData.filter(
      (item) => item.surveyAnswerConfigId == selectedAnswerId
    );
    if (filterData.length > 0) {
      step = filterData[0].stepAnswer;
    }
    if (step == 0) {
      next();
    } else {
      getQuestionIndex(step);
    }
    return;
  };

  const multimediaAswer = () => {
    localAttachments.map((file, index) => {
      setTimeout(() => {
        var fileUpload = useSurveyUserProfileAnswer
          .addSurveyMultimedia(
            {
              SurveyAnswerConfigId: question.surveyQuestionId,
              SurveyId: surveyUserProfileId,
            },
            file
          )
          .then((result) => {
            if (result != null) {
              setSelectedValue(result);
              addAswerInfo(result);
            } else setQuestionIndexSave(-1);
          })
          .catch((error) => setQuestionIndexSave(-1));
      }, 1023 * index);
    });
  };

  const scaleAnswer = () => {
    var isFull = true;
    Object.keys(radioValues).map((x) => {
      if (radioValues[x] == 0) {
        swal({
          text: "Completa todos los campos.",
          icon: "error",
        });
        setQuestionIndexSave(-1);
        isFull = false;
      }
    });
    if (isFull) {
      Object.keys(radioValues).map((x, index) => {
        setTimeout(() => {
          addAswerScale(x, radioValues[x]);
        }, 543 * index);
      });
      if (questionCount == questionIndex) {
        setTimeout(() => {
          swal({
            text: "La encuesta se ha completado",
            icon: "success",
          }).then(() => window.location.reload());
        }, 2345);
      }
      next();
    }
    return;
  };

  const addAswerInfo = (linkValue) => {
    if (userAnswer.surveyUserProfileAnswerId) {
      var upd = {
        surveyUserProfileAnswerId: userAnswer.surveyUserProfileAnswerId,
        surveyUserProfileId: surveyUserProfileId,
        surveyQuestionId: userAnswer.surveyQuestionId,
        selectedAnswerConfigId: selectedAnswerId,
        value: linkValue,
      };
      useSurveyUserProfileAnswer
        .updateSurveyUserProfileAnswer(upd)
        .then((result) => {
          if (result != null) {
            if (result.isSurveyCompleted) {
              swal({
                text: "La encuesta se ha completado",
                icon: "success",
              }).then(() => window.location.reload());
            } else {
              setUserAnswer(result.userAnswer);
              next();
            }
          } else setQuestionIndexSave(-1);
        })
        .catch((error) => setQuestionIndexSave(-1));
    } else {
      var ins = {
        surveyUserProfileId: surveyUserProfileId,
        surveyQuestionId: question.surveyQuestionId,
        selectedAnswerConfigId: selectedAnswerId,
        value: linkValue,
      };
      useSurveyUserProfileAnswer
        .createSurveyUserProfileAnswer(ins)
        .then((result) => {
          if (result != null) {
            if (result.isSurveyCompleted) {
              swal({
                text: "La encuesta se ha completado",
                icon: "success",
              }).then(() => window.location.reload());
            } else {
              setUserAnswer(result.userAnswer);
              next();
            }
          } else setQuestionIndexSave(-1);
        })
        .catch((error) => setQuestionIndexSave(-1));
    }
  };

  const addAswerScale = async (id, response) => {
    var filterData = selectedAnswers.filter(
      (item) => item.selectedAnswerConfigId == id
    );
    var oldSurveyUserProfileAnswerId = 0;
    var oldSurveyQuestionId = 0;
    if (filterData.length > 0) {
      oldSurveyUserProfileAnswerId = filterData[0].surveyUserProfileAnswerId;
      oldSurveyQuestionId = filterData[0].surveyQuestionId;
    }
    if (oldSurveyUserProfileAnswerId > 0 && oldSurveyQuestionId > 0) {
      var upd = {
        surveyUserProfileAnswerId: parseInt(oldSurveyUserProfileAnswerId),
        surveyUserProfileId: surveyUserProfileId,
        surveyQuestionId: parseInt(oldSurveyQuestionId),
        selectedAnswerConfigId: parseInt(id),
        value: response.toString(),
      };
      await useSurveyUserProfileAnswer
        .updateSurveyUserProfileAnswer(upd)
        .then((result) => {})
        .catch((error) => setQuestionIndexSave(-1));
    } else {
      var ins = {
        surveyUserProfileId: surveyUserProfileId,
        surveyQuestionId: question.surveyQuestionId,
        selectedAnswerConfigId: parseInt(id),
        value: response.toString(),
      };
      await useSurveyUserProfileAnswer
        .createSurveyUserProfileAnswer(ins)
        .then((result) => {
          if (result != null) {
          } else setQuestionIndexSave(-1);
        })
        .catch((error) => setQuestionIndexSave(-1));
    }
  };

  const addSingleAswerScale = async (id, response) => {
    var filterData = selectedAnswers.filter(
      (item) => item.selectedAnswerConfigId == id
    );
    var oldSurveyUserProfileAnswerId = 0;
    var oldSurveyQuestionId = 0;
    if (filterData.length > 0) {
      oldSurveyUserProfileAnswerId = filterData[0].surveyUserProfileAnswerId;
      oldSurveyQuestionId = filterData[0].surveyQuestionId;
    }
    if (oldSurveyUserProfileAnswerId > 0 && oldSurveyQuestionId > 0) {
      var upd = {
        surveyUserProfileAnswerId: parseInt(oldSurveyUserProfileAnswerId),
        surveyUserProfileId: surveyUserProfileId,
        surveyQuestionId: parseInt(oldSurveyQuestionId),
        selectedAnswerConfigId: parseInt(id),
        value: response.toString(),
      };
      await useSurveyUserProfileAnswer
        .updateSurveyUserProfileAnswer(upd)
        .then((result) => {})
        .catch((error) => setQuestionIndexSave(-1));
    } else {
      var ins = {
        surveyUserProfileId: surveyUserProfileId,
        surveyQuestionId: question.surveyQuestionId,
        selectedAnswerConfigId: parseInt(id),
        value: response.toString(),
      };
      await useSurveyUserProfileAnswer
        .createSurveyUserProfileAnswer(ins)
        .then((result) => {
          if (result != null) {
            useSurveyUserProfileAnswer
              .getSurveysUserProfileAnswersListByQuestionId(
                question.surveyQuestionId,
                surveyUserProfileId
              )
              .then((result) => {
                if (result) {
                  setSelectedAnswers(result);
                }
              });
          } else setQuestionIndexSave(-1);
        })
        .catch((error) => setQuestionIndexSave(-1));
    }
  };

  const imageExt = ["jpg", "jpeg", "png"];

  const handleOnChangeFile = (event, answer) => {
    let fileList = [];
    setSelectedAnswerId(answer.surveyAnswerConfigId);
    if (event.target.files != null && event.target.files.length > 0) {
      var targetfiles = event.target.files;

      for (var i = 0; i < targetfiles.length; i++) {
        var file = targetfiles[i];
        var size = file.size / 1024 / 1024;
        if (size < maxSize) {
          fileList = [...fileList, file];
        } else {
          swal({
            text: "Los archivos debe ser menores a " + maxSize + " MB.",
            icon: "error",
          });
          event.target.value = null;
          break;
        }
      }
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(file.name)[1];
      if (imageExt.includes(ext)) {
      } else {
        swal({
          text: "Formato no válido.",
          icon: "error",
        });
        event.target.value = null;
        return;
      }
      setLocalAttachments(fileList);
    }
  };  

  const handleBlur = (data) => {
    var filterData = data.filter(
      (item) => item.active
    );
    if (filterData.length > 0) {
      getAnswers(question);
    }
  }
  const handleFocus = () => {
    return 0;
  }

  useEffect(() => {
    if(answerData.length>0) {
      window.addEventListener("focus", handleFocus);
      window.addEventListener("blur", ()=>handleBlur(answerData));
    }
  }, [answerData]);


  return (
    <div className="box_resp">
      {question.surveyAnswerTypeId == 3 ? (
        <div className="wrapper__groups table-responsive">
          <table className="table table-borderless align-middle">
            <thead>
              <tr className="table-active">
                <th scope="col"></th>
                {answerScale.map((s) => (
                  <th scope="col">{s.description}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {answerData.map(
                (answer) =>
                  answer.active && (
                    <tr key={"que" + answer.surveyAnswerConfigId}>
                      <th className="text-left table-active" scope="row">
                        {answer.answer}
                      </th>
                      {answerScale.map((s) => (
                        <td
                          key={
                            "ans" +
                            s.surveyScaleAnswerId +
                            s.surveyScaleAnswerId
                          }
                        >
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={(event) =>
                                handleRadioChange(
                                  event,
                                  answer,
                                  s.surveyScaleAnswerId
                                )
                              }
                              name={"que" + answer.surveyAnswerConfigId}
                              id={
                                "ans" +
                                s.surveyScaleAnswerId +
                                "id" +
                                answer.surveyAnswerConfigId
                              }
                              ref={(el) =>
                                (myRefs.current[
                                  "ans" +
                                    s.surveyScaleAnswerId +
                                    "id" +
                                    answer.surveyAnswerConfigId
                                ] = el)
                              }
                            />
                          </div>
                        </td>
                      ))}
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
      ) : (
        answerData.map((answer) => (
          <div key={answer.surveyAnswerConfigId}>
            {
              {
                1: answer.active && (
                  <div className="form-group p-1">
                    <input
                      type="text"
                      value={selectedValue}
                      onChange={(event) => handleTextChange(event, answer)}
                      className="form-control"
                      onKeyDown={(e) => { tabLock(e) }}
                      tabIndex="-1"
                    />
                  </div>
                ),

                2: answer.active && (
                  <div className=" custom-control custom-radio ml-4 mb-3 text-left">
                    <input
                      type="radio"
                      name={"Q" + question.surveyQuestionId}
                      id={"Q" + answer.surveyAnswerConfigId}
                      value={answer.surveyAnswerConfigId}
                      checked={selectedAnswerId === answer.surveyAnswerConfigId}
                      onChange={(event) => handleOptionChange(event, answer)}
                      className="custom-control-input"
                      tabIndex="-1"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={"Q" + answer.surveyAnswerConfigId}
                    >
                      {answer.isImage ? (
                        <img
                          className="opt_img_answer"
                          src={answer.answer}
                          alt="Tu opinion en kuimby"
                        />
                      ) : (
                        answer.answer
                      )}
                    </label>
                  </div>
                ),

                4: answer.active && (
                  <div className="form-group p-1">
                    <input
                      type="text"
                      value={selectedValue}
                      onChange={(event) => handleTextChange(event, answer)}
                      className="form-control"
                      tabIndex="-1"
                    />
                  </div>
                ),

                5: answer.active && (
                  <div className="custom-file mbb">
                    <input
                      className="custom-file__input"
                      type="file"
                      id={"Q" + answer.surveyAnswerConfigId}
                      name={"Q" + question.surveyQuestionId}
                      multiple={answer.isImage}
                      accept="image/*"
                      onChange={(event) => handleOnChangeFile(event, answer)}
                      tabIndex="-1"
                      required
                    />
                    <div className="clearfix text-left p-1">
                      <br />
                      {answer.answer}
                    </div>
                    <label
                      className="custom-file__label"
                      htmlFor={"Q" + answer.surveyAnswerConfigId}
                    >
                      <i className="fas fa-upload"></i> Subir archivo
                    </label>
                  </div>
                ),
                6: answer.active && (
                  <div className="box_heat_maps">
                    <iframe
                      id={"Q" + answer.surveyAnswerConfigId}
                      title="Encuestas en Comuniviv"
                      height="550"
                      src={answer.answer}
                    ></iframe>
                  </div>
                ),
              }[question.surveyAnswerTypeId.toString()]
            }
          </div>
        ))
      )}
    </div>
  );
}
