import React, { useEffect, useState } from "react";
import moment from "moment";
import { ItemInfoPost } from "./itemInfoPost/itemInfoPost";
import { useFrontDiary } from "../services/diary.service";
import "moment/locale/es";

export function BoxSchedule() {
  const [DiaryData, setDiaryData] = useState({ Diary: [] });
  async function getCompanyDiary() {
    useFrontDiary
      .getFrontCompanyDiaryFiltered(
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
        null,
        false
      )
      .then((result) => {
        if (result) {
          result.forEach((d) => {
            d.startDate = new Date(d.startDate);
            d.endDate = new Date(d.endDate);
          });
          setDiaryData({ Diary: result });
        }
      });
  }
  useEffect(() => {
    getCompanyDiary();
  }, []);
  return (
    <div className="box_schedule drop_home shadows bradius">
      <h4 className="text fnt_medium">Calendario</h4>
      <div className="content_drop">
        <span
          className="text-muted span_month"
          style={{ textTransform: "capitalize" }}
        >
          {moment().format("MMMM")}
        </span>
        {DiaryData.Diary.map((item) => (
          <ItemInfoPost key={item.CompanyDiaryId} currentEvent={item} />
        ))}
      </div>
    </div>
  );
}
