import React, { useEffect, useState } from "react";
import useInputForm from "../../components/CustonHooks/FormHook";
import { useQuestion } from "../../services/surveyQuestion.service";
import { SelectCustom } from "../../components/Helpers/SelectCustom";
import { Link } from "react-router-dom";

export function EditQuestion(props) {
  const onSave = () => {
    if (inputs.SurveyQuestionId && inputs.SurveyQuestionId > 0)
      useQuestion
        .updateQuestion({
          SurveyQuestionId: parseInt(inputs.SurveyQuestionId),
          SurveyId: parseInt(inputs.SurveyId),
          SurveyAnswerTypeId: parseInt(inputs.SurveyAnswerTypeId),
          Question: inputs.Question,
          SurveyScaleId: parseInt(inputs.SurveyScaleId),
        })
        .then((result) => {
          if (props.onSave) props.onSave();
        });
    else
      useQuestion
        .createQuestion({
          SurveyId: parseInt(inputs.SurveyId),
          SurveyAnswerTypeId: parseInt(inputs.SurveyAnswerTypeId),
          Question: inputs.Question,
          SurveyScaleId: parseInt(
            inputs.SurveyScaleId ? inputs.SurveyScaleId : 0
          ),
        })
        .then((result) => {
          if (props.onSave) props.onSave();
        });
  };
  const handleOnCancel = () => {
    if (props.onCancelEdit) props.onCancelEdit();
  };
  const [question, setQuestion] = useState({
    SurveyQuestionId: "",
    SurveyId: props.surveyId,
    SurveyAnswerTypeId: "",
    Question: "",
    SurveyScaleId: "",
  });
  const [refreshAnswers, setRefreshAnswers] = useState(0);
  const [showEditAnswer, setShowEditAnswer] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState();
  const { handleSubmit, handleInputChange, inputs } = useInputForm(
    onSave,
    question
  );

  useEffect(() => {
    let newP = {};
    if (props.currentQuestion) {
      newP = {
        SurveyQuestionId: props.currentQuestion.SurveyQuestionId.toString(),
        SurveyId: props.currentQuestion.SurveyId.toString(),
        SurveyAnswerTypeId: props.currentQuestion.SurveyAnswerTypeId.toString(),
        Question: props.currentQuestion.Question,
        SurveyScaleId: props.currentQuestion.SurveyScaleId,
      };
      setQuestion(newP);
    } else
      setQuestion({
        SurveyQuestionId: "",
        SurveyId: props.surveyId,
        SurveyAnswerTypeId: "",
        Question: "",
        SurveyScaleId: "",
      });
    setRefreshAnswers((prev) => prev + 1);
    setSelectedAnswer(null);
    setShowEditAnswer(false);
  }, [props.currentQuestion]);

  return (
    <div className="content_new_question">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <input
            type="hidden"
            name="SurveyQuestionId"
            onChange={handleInputChange}
            value={inputs.SurveyQuestionId}
          />
          <input
            type="hidden"
            name="SurveyId"
            onChange={handleInputChange}
            value={inputs.SurveyId}
          />
          <div className="col-12">
            <div className="form-group">
              <label for="">Pregunta</label>
              <input
                type="text"
                className="form-control"
                name="Question"
                onChange={handleInputChange}
                value={inputs.Question}
                required
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label for="">Tipo de respuesta</label>
              <SelectCustom
                method="SurveyAnswerType"
                textField="description"
                valueField="surveyAnswerTypeId"
                selectName="SurveyAnswerTypeId"
                handleOnChange={handleInputChange}
                selectValue={inputs.SurveyAnswerTypeId}
              />
            </div>
            {inputs.SurveyAnswerTypeId == 3 && (
              <div className="ans_type_scala">
                <div className="form-group mb-1">
                  <label for="">Tipo de Escala</label>
                  <SelectCustom
                    method="SurveyScale"
                    textField="description"
                    valueField="surveyScaleId"
                    selectName="SurveyScaleId"
                    handleOnChange={handleInputChange}
                    selectValue={inputs.SurveyScaleId}
                  />
                </div>
                <div className="text-right mb-4">
                  <Link className="link_action" to="/AdminAnswerScales">
									<i className="fas fa-plus"></i> Agregar escala de valor
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="mt-2 text-right">
          <button
            className="btn secundary minimum ml-1 mr-1"
            type="button"
            onClick={handleOnCancel}
          >
            Cancelar
          </button>
          <button className="btn primary minimum ml-1 mr-1" type="submit">
            {
              inputs.SurveyQuestionId && inputs.SurveyQuestionId > 0?
                <p>Actualizar</p>
              :
                <p>Agregar</p>
            }
          </button>
        </div>
      </form>
    </div>
  );
}
