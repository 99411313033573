import React, { useEffect, useState, useRef } from "react";
import { chatService } from "./../../services/chat.service";
import { TtlsChats } from "./ttlsChats";
import { AvatarChats } from "./avatarChats";
import { ItemConversation } from "./itemConversation";
import InfiniteScroll from "react-infinite-scroll-component";

export function ChatConversations({ currentChat, newMessageReceived, readNotification, toggle, openChat }) {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [dateTime, setDateTime] = useState(null);
    const inputElementRef = useRef();

    useEffect(() => {
        if (Object.keys(currentChat).length > 0) {
            chatService.getMessages(currentChat.contactId).then((result) => {
                setMessages(result)
                if (result.length > 0) {
                    setDateTime(result.at(-1).date);
                    chatService.updateRead(currentChat.contactId);
                }
                else setDisabled(true);
            });
        }
    }, [currentChat]);

    useEffect(() => {
        if (Object.keys(newMessageReceived).length > 0 && openChat) {
            setMessages([newMessageReceived, ...messages]);
            if (!newMessageReceived.isSender) chatService.updateRead(currentChat.contactId);
        }
    }, [newMessageReceived, openChat]);

    useEffect(() => {
        if (readNotification != "") {
            let messagesUnread = messages.map(m => {
                if (m.messageId == readNotification)
                    m.status = "Read";
                return m;
            });
            setMessages(messagesUnread);
        }
    }, [readNotification]);

    const [disabled, setDisabled] = React.useState(false);
    const timelineRef = React.useRef();

    const onSubmit = (e) => {
        e.preventDefault();
        if (newMessage != "") {
            timelineRef.current.scrollTo(0, timelineRef.current.scrollHeight);
            const chatMessage = {
                message: newMessage,
                receiver: {
                    id: currentChat.contactId,
                    name: currentChat.name,
                    image: currentChat.image,
                },
            };

            chatService.sendMessage(chatMessage).then(() => {
                setNewMessage("");
                inputElementRef.current.focus();
            });
        }
    };

    const loadMore = () => {
        chatService.getMessages(currentChat.contactId, dateTime).then((result) => {
            if (result.length > 0) {
                setMessages([...messages, ...result]);
                setDateTime(result.at(-1).date);
            }
            setDisabled(true);
        });
    };

    return (
        <div className="sections_chat active_sec wrapper_chat_conversations">
            <div className="sec_info_chats">
                <div className="to_back" onClick={() => toggle("1")}>
                    <i className="fas fa-chevron-left"></i>
                </div>
                <AvatarChats img={currentChat.image} />
                <div className="box_info">
                    <TtlsChats title={currentChat.name} />
                    {/*<p className="m-0">Activo</p>*/}
                </div>

                {/*<div className="btn-group">*/}
                {/*    <span*/}
                {/*        className="btn_ico_more dropdown-toggle"*/}
                {/*        type="button"*/}
                {/*        data-toggle="dropdown"*/}
                {/*        aria-haspopup="false"*/}
                {/*        aria-expanded="false"*/}
                {/*    >*/}
                {/*        <ion-icon name="ellipsis-vertical-outline"></ion-icon>*/}
                {/*    </span>*/}
                {/*    <div className="dropdown-menu dropdown-menu-right">*/}
                {/*        <button className="dropdown-item" type="button">*/}
                {/*            <span>Descargar conversación</span>*/}
                {/*            <span>*/}
                {/*                <ion-icon name="download-outline"></ion-icon>*/}
                {/*            </span>*/}
                {/*        </button>*/}
                {/*        <div className="dropdown-divider"></div>*/}
                {/*        <button className="dropdown-item" type="button">*/}
                {/*            <span>Eliminar</span>*/}
                {/*            <span>*/}
                {/*                <ion-icon name="trash-outline"></ion-icon>*/}
                {/*            </span>*/}
                {/*        </button>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            <div
                className="sec_conten_conversations p-3"
                id="scrollableDiv"
                ref={timelineRef}
            >
                <InfiniteScroll
                    dataLength={messages.length}
                    next={loadMore}
                    style={{ display: "flex", flexDirection: "column-reverse" }}
                    inverse={true}
                    hasMore={true && !disabled}
                    loader={<h4>Cargando...</h4>}
                    scrollableTarget="scrollableDiv"
                >
                    {messages.map((item) => {
                        return item.isSender ? (
                            <ItemConversation
                                key={item.messageId}
                                source="out"
                                author="Tu."
                                checkMnj={item.status == "Read"}
                                dateTime={item.date}
                                chatMnj={item.message}
                            />
                        ) : (
                                <ItemConversation
                                    key={item.messageId}
                                    source="in"
                                    author={item.senderName}
                                    checkMnj={true}
                                    dateTime={item.date}
                                    chatMnj={item.message}
                                />
                            );
                    })}
                </InfiniteScroll>
            </div>

            <div className="sec_content_typing">
                {!currentChat.readOnly && <form onSubmit={onSubmit}>
                    <div className="form-group box_intro_text">
                        <textarea
                            className="form-control "
                            id="message"
                            placeholder="Escribe un mensaje aquí"
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            ref={inputElementRef}
                        ></textarea>
                        <button type="submit" className="btn" disabled={!newMessage}>
                            <i className="fas fa-paper-plane"></i>
                        </button>
                    </div>
                </form>}
            </div>
        </div>
    );
}
