import React, { useState } from "react";
// import { ListGroup, ListGroupItem } from "reactstrap";
import Moment from "react-moment";
import "moment/locale/es";
import { useSelector } from "react-redux";

export function ListSurveys({ surveys, onSelectSurvey, handleViewSurvey }) {
  const [surveyActive, setSurveyActive] = useState(0);

  const calendarStrings = useSelector((state) => state.calendarStrings);

  return (
    <div className="table-responsive">
      <table className="wrapper_table table table-hover table-sm">
        <thead>
          <tr>
            <th>Encuesta</th>
            <th>Reactivos</th>
            <th>Puntos</th>
            <th>Fecha de vencimiento</th>
          </tr>
        </thead>
        <tbody>
          {surveys.map((survey) => (
            <tr
              key={survey.surveyUserProfileId}
              className={`${surveyActive == survey.surveyId ? "active" : ""}`}
            >
              <td>
                <span
                  className="c_primary cursor text fnt_medium"
                  onClick={() => {
                    onSelectSurvey(survey);
                    setSurveyActive(survey.surveyId);
                    handleViewSurvey();
                  }}
                >
                  {survey.title}
                </span>
              </td>
              <td> {survey.questionCount}</td>
              <td> {survey.reward}</td>
              <td>
                <Moment calendar={calendarStrings}>{survey.endDate}</Moment>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
