import React, { useState, useEffect, Fragment } from "react";
import "./boxUserProfile.scss";
import { Link } from "react-router-dom";
import { backendService } from "./../../services/backend.service";
import { useAcknowledgment } from "../../services/acknowledgment.service";
import { userProfileService } from "../../services/backoffice/userProfile.service";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

let additionalEmailSwal = {
    title: "Cuenta de correo adicional",
    focusConfirm: false,
    html: `
    <span>La cuenta de correo adicional será ocupada para la recuperación de tu contraseña en caso de requerirse</span>
  `,
    input: "email",
    type: "warning",
    showCancelButton: true,
    cancelButtonColor: "grey",
    cancelButtonText: "Más tarde",
    confirmButtonText: "Guardar",
    allowOutsideClick: false,
    validationMessage: "El formato de correo no es correcto",
};

export function BoxUserProfile() {
    const [profile, setProfileInfo] = useState();
    const [acknowledgmentLadder, setAcknowledgmentLadder] = useState([]);
    const resetPw = async (data) => {
        const swalval = await MySwal.fire(additionalEmailSwal);
        let v = (swalval && swalval.value) || swalval.dismiss;
        if (v || v === "cancel") {
            if (v !== "cancel") {
                data.additionalEmail = v;
                userProfileService.editAdditionalEmail(data);
            } else {
                await MySwal.fire({
                    type: "error",
                    title: "Puedes actualizar ese correo en editando tu perfil",
                });
            }
        } else {
            await MySwal.fire({
                type: "error",
                title: "Puedes actualizar ese correo en editando tu perfil",
            });
        }
    };

    useEffect(() => {
        backendService.userProfile().then((data) => {
            if (!data.additionalEmail && data.isCorporateUser == false) {
                let id = localStorage["userId"];
                userProfileService.getById(id).then((userdata) => resetPw(userdata));
            }
            setProfileInfo(data);
        });
        useAcknowledgment
            .getAcknowledgmentUserProfile()
            .then((data) => setAcknowledgmentLadder(data));
    }, []);

    return (
        <div className="wrapper_box_user_profile">
            <div className="drawer_user_profile">
                <Link to="/profile" className="ico_tool">
                    <i className="fas fa-cog"></i>
                </Link>
                <div >
                    {/* <div className="box_data_user"> */}
                    {/*  <div>*/}
                    {/*    <h6>No. Empleado</h6>*/}
                    {/*    <p>{profile && profile.employeeNo}</p>*/}
                    {/*  </div>*/}
                    {/*  <div>*/}
                    {/*    <h6>Dirección</h6>*/}
                    {/*    <p>{profile && profile.deparment}</p>*/}
                    {/*  </div>*/}
                    {/*  <div>*/}
                    {/*    <h6>Antigüedad</h6>*/}
                    {/*    <p> {profile && profile.seniority}</p>*/}
                    {/*  </div>*/}
                    {/* </div> */}
                    <div className="box_avatar_user">
                        <span className="avatar">
                            <img src={profile && profile.urlProfileImage} alt="NameUser" />
                        </span>
                    </div>
                </div>

                <div className="box_info_user text-center">
                    <h4 className="mb-0 name">
                        {" "}
                        {profile && profile.middleName} {profile && profile.lastName}{" "}
                        {profile && profile.firstName}
                    </h4>
                    <h5 className="mb-0">{profile && profile.nickname}</h5>
                    {/*<h6 className="mb-0">{profile && profile.jobRole}</h6>*/}
                </div>
                {/*<div className="box_data_user">*/}
                {/*  <div>*/}
                {/*    <h6>No. Empleado</h6>*/}
                {/*    <p>{profile && profile.employeeNo}</p>*/}
                {/*  </div>*/}
                {/*  <div>*/}
                {/*    <h6>Dirección</h6>*/}
                {/*    <p>{profile && profile.deparment}</p>*/}
                {/*  </div>*/}
                {/*  <div>*/}
                {/*    <h6>Antigüedad</h6>*/}
                {/*    <p> {profile && profile.seniority}</p>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="row">
                    <div className="col text-center box_insignias">
                        {acknowledgmentLadder.map((ladder, index) => (
                            <Fragment key={index}>
                                {ladder.logo ? (
                                    <img src={ladder.logo} alt={ladder.title} />
                                ) : (
                                        <span>
                                            <i className="fas fa-star"></i>
                                        </span>
                                    )}
                            </Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
