import React, { Component } from "react";
import Slider from "react-slick";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useQuestion } from "../../services/surveyQuestion.service";
import { useSurvey } from "../../services/survey.service";
import { SurveyChart } from "./surveyChart";
import { SurveyChartScale } from "./surveyChartScale";
import { SurveyAnswerList } from "./surveyAnswerList";
import { Link } from "react-router-dom";

import "./surveyResult.scss";

export class SurveyResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveyInfo: [],
      questions: [],
      questionIndex: 1,
      questionCount: 0,
      questionIndexSave: -1,
      chartGrouping: 1,
    };
  }

  componentWillMount() {
    this.getSurveyInfo(this.props.match.params.id);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.getSurveyInfo(nextProps.match.params.id);
    }
  }

  handleToggleGroupChange = (event, value) => {
    this.setState({ chartGrouping: value });
  };

  getSurveyInfo = async (surveyId) => {
    useSurvey.getSurveysInfoById(surveyId).then((result) => {
      if (result) {
        this.setState({
          surveyInfo: result,
          questionCount: result.questionCount,
          questions: result.questionList,
          questionIndex: 1,
        });
      }
    });
  };

  setQuestionIndexSave = (value) => {
    this.setState({ questionIndexSave: value });
  };

  handleQuestionChange = (isNext) => {
    if (!isNext) this.slider.slickPrev();
    else this.slider.slickNext();

    this.setState({ chartGrouping: 1 });
    this.setQuestionIndexSave(isNext ? this.state.questionIndex - 1 : -1);
  };

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      beforeChange: (current, next) =>
        this.setState({ questionIndex: next + 1 }),
      adaptiveHeight: true,
      swipe: false,
    };

    const {
      questions,
      questionIndex,
      questionCount,
      questionIndexSave,
      surveyInfo,
      chartGrouping,
    } = this.state;
    return (
      <div>
        <section className="wrapper_profile padd">
        <div className="mb-3">
        <Link className="link_action"  to={"/details-survey/" + this.props.match.params.id}>
          <i className="fas fa-angle-left"></i> Regresar
        </Link>
      </div>
          <div className="mb-4 divtop">
            <div className="box_ttl_opt">
              <h2 className="mb-0 text fnt_medium">Resultado de la encuesta</h2>
            </div>
          </div>

          <div className="content_profile">
            <div className="box_main_info_profile">
              <div className="box_details_account card shadows mt-0">
                <h5 className="text fnt_medium c_primary">{surveyInfo.title}</h5>
                <a className="text-absRight">
                  Pregunta <span>{questionIndex} </span>/{" "}
                  <span>{questionCount}</span>
                </a>
                <hr className="mt-0" />
                <div className="drawer_acount_details">
                  <div className="section_surveys">
                    <div className="box_arrows">
                      <span
                        className="arrow lf"
                        onClick={() => this.handleQuestionChange(false)}
                      >
                        <i className="fas fa-angle-left mr-2"></i> Anterior
                      </span>
                      <span
                        className="arrow rg"
                        onClick={() => this.handleQuestionChange(true)}
                      >
                        Siguiente
                        <i className="fas fa-angle-right ml-2"></i>
                      </span>
                    </div>
                    <Slider ref={(c) => (this.slider = c)} {...settings}>
                      {questions.map((question, index) => (
                        <div className="w-100">
                          <div className="outline">
                            {question.surveyAnswerTypeId == 2 ? (
                              <div>
                                <ToggleButtonGroup
                                  color="primary"
                                  value={this.state.chartGrouping}
                                  exclusive
                                  onChange={this.handleToggleGroupChange}
                                >
                                  <ToggleButton value="1">General</ToggleButton>
                                  <ToggleButton value="2">
                                    Por Género
                                  </ToggleButton>
                                  <ToggleButton value="3">
                                    Por Edad
                                  </ToggleButton>
                                  <ToggleButton value="4">
                                    Por Segmento
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              </div>
                            ) : (
                              <h5 className="mb-0 text fnt_medium">
                                {
                                  {
                                    1:(
                                      <p>Pregunta de respuesta abierta</p>
                                    ),
                                    2:(
                                      <p>Pregunta de opción multiple</p>
                                    ),
                                    3:(
                                      <p>Pregunta Escala de Valoración</p>
                                    ),
                                    5:(
                                      <p>Pregunta multimedia</p>
                                    )
                                  }[question.surveyAnswerTypeId]
                                }
                                {question.question}
                              </h5>
                            )}
                            <div className="answers">
                              {
                                {
                                  2:(
                                    <SurveyChart
                                      surveyId={surveyInfo.surveyId}
                                      surveyQuestionId={question.surveyQuestionId}
                                      chartGrouping={chartGrouping}
                                      questionTitle={question.question}
                                      anwerTypeId={question.surveyAnswerTypeId}
                                    ></SurveyChart>
                                  ),
                                  3:(
                                    <SurveyChartScale
                                      surveyId={surveyInfo.surveyId}
                                      surveyScaleId={question.surveyScaleId}
                                      surveyQuestionId={question.surveyQuestionId}
                                      anwerTypeId={question.surveyAnswerTypeId}
                                    ></SurveyChartScale>
                                  )

                                }[question.surveyAnswerTypeId.toString()] || 
                                <SurveyAnswerList
                                  questionId={question.surveyQuestionId}
                                  surveyAnswerTypeId={question.surveyAnswerTypeId}
                                ></SurveyAnswerList>
                              }
                              {/* {question.surveyAnswerTypeId == 2 ? (
                                <SurveyChart
                                  surveyId={surveyInfo.surveyId}
                                  surveyQuestionId={question.surveyQuestionId}
                                  chartGrouping={chartGrouping}
                                  questionTitle={question.question}
                                  anwerTypeId={question.surveyAnswerTypeId}
                                ></SurveyChart>
                              ) : (
                                <SurveyAnswerList
                                  questionId={question.surveyQuestionId}
                                  surveyAnswerTypeId={
                                    question.surveyAnswerTypeId
                                  }
                                ></SurveyAnswerList>
                              )} */}
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                    
                    <div className="box_arrows">
                      <span
                        className="arrow lf"
                        onClick={() => this.handleQuestionChange(false)}
                      >
                        <i className="fas fa-angle-left mr-2"></i> Anterior
                      </span>
                      <span
                        className="arrow rg"
                        onClick={() => this.handleQuestionChange(true)}
                      >
                        Siguiente
                        <i className="fas fa-angle-right ml-2"></i>
                      </span>
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
