import * as Yup from 'yup';
import moment from 'moment';

export const schemaPage1 = Yup.object().shape({
    firstName: Yup.string()
        .required('Este campo es requerido')
        .matches(/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g, "Este campo solo admite letras"),
    middleName: Yup.string()
        .required('Este campo es requerido')
        .matches(/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g, "Este campo solo admite letras"),
    lastName: Yup.string()
        .required('Este campo es requerido')
        .matches(/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g, "Este campo solo admite letras"),
    gender: Yup.string().nullable()
        .required('Este campo es requerido'),
    birthday: Yup.date()
        .typeError('Este campo es requerido')
        .min('1900-01-01', "El valor debe ser mayor o igual a 01/01/1900")
        .max(moment(new Date()).format('YYYY-MM-DD'), "El valor debe ser menor o igual a " + moment(new Date()).format('DD/MM/YYYY'))
        .required('Este campo es requerido'),
    mobile: Yup.string().when('mobile', {
        is: (value) => value?.length,
        then: (rule) => rule.matches(/^[0-9]{10}$/g, 'No parece un número de teléfono valido'),
    }),
    phoneNumber: Yup.string().when('phoneNumber', {
        is: (value) => value?.length,
        then: (rule) => rule.matches(/^[0-9]{10}$/g, "No parece un número de teléfono valido"),
    }),
    extension: Yup.string()
},
    [
        // Add Cyclic deps here because when require itself
        ['mobile', 'mobile'],
        ['phoneNumber', 'phoneNumber']
    ]
);

export const schemaPage2 = Yup.object().shape({
    email: Yup.string()
        .required('Este campo es requerido')
        .email('No parece un email valido'),
    password: Yup.string()
        .required('Este campo es requerido'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
        .required('Este campo es requerido')
});
