import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { useSurvey } from "../../services/survey.service";

export function SearchSurveyScale(props) {
  const [surveysData, setSurveysData] = useState({ surveys: [] });
  const handleDelete = (data) => {
    useSurvey.deleteSurvey(data).then((result) => {
      getSurveys();
    });
  };
  const [selectedSurvey, setSelectedSurvey] = useState();

  let dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  useEffect(() => {
    getSurveys();
  }, [props.refresh]);

  async function getSurveys() {
    useSurvey.getSurveyScales().then((result) => {
      if (result) {
        console.log("result", result);
        setSurveysData({ surveys: result });
      }
    });
  }

	return (
		<div className="table-responsive card shadows p-3">
			<table className="wrapper_table table table-hover table-sm">

				<thead>
					<tr>
						<th className="text fnt_medium">ID Escala</th>
						<th className="text fnt_medium min_width">Titulo de Escala</th>
						{/* <th className="text fnt_medium min_width">Respuestas</th> */}
						<th className="text fnt_medium">Estatus</th>
						<th className="text fnt_medium">Acciones</th>
					</tr>
				</thead>
				<tbody>
					{surveysData.surveys.map(survey => (
						<tr key={survey.surveyId}>
							{console.log(survey)}
							<td>
								<Link className="link_data" to={"/survey-scale/" + survey.surveyScaleId}>{survey.surveyScaleId}</Link>
							</td>
							<td>
								<Link className="link_data" to={"/survey-scale/" + survey.surveyScaleId}>{survey.description}</Link>
							</td>
							{/* <td>
								{survey.surveyedCount}
							</td> */}
							<td>{survey.active?'Activa':'Inactiva'}</td>
							<td>
								<span className="icon_tables" onClick={() => handleDelete(survey)}>
									<i className="far fa-trash-alt"></i>
								</span>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>

	);
}
