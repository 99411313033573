import React, { useEffect, useState } from "react";
import useInputForm from "../../components/CustonHooks/FormHook";
import { useQuestion } from "../../services/surveyQuestion.service";
import { SearchAnswer } from "./searchAnswers";
import { EditAnswer } from "./answer";

export function EditQuestionAnswer(props) {
    const onSave = () => {
        if (inputs.SurveyQuestionId && inputs.SurveyQuestionId > 0)
            useQuestion
                .updateQuestion({
                    SurveyQuestionId: parseInt(inputs.SurveyQuestionId),
                    SurveyId: parseInt(inputs.SurveyId),
                    SurveyAnswerTypeId: parseInt(inputs.SurveyAnswerTypeId),
                    Question: inputs.Question,
                })
                .then((result) => {
                    if (props.onSave) props.onSave();
                });
        else
            useQuestion
                .createQuestion({
                    SurveyId: parseInt(inputs.SurveyId),
                    SurveyAnswerTypeId: parseInt(inputs.SurveyAnswerTypeId),
                    Question: inputs.Question,
                })
                .then((result) => {
                    if (props.onSave) props.onSave();
                });
    };

    const [question, setQuestion] = useState({
        SurveyQuestionId: "",
        SurveyId: props.surveyId,
        SurveyAnswerTypeId: "",
        Question: "",
    });
    const [refreshAnswers, setRefreshAnswers] = useState(0);
    const [showEditAnswer, setShowEditAnswer] = useState(false);
    const [showEditAnswerMulti, setShowEditAnswerMulti] = useState(true);
    const [selectedAnswer, setSelectedAnswer] = useState();
    const { inputs } = useInputForm(onSave, question);

  useEffect(() => {
    let newP = {};
    if (props.currentQuestion) {
      newP = {
        SurveyQuestionId: props.currentQuestion.SurveyQuestionId.toString(),
        SurveyId: props.currentQuestion.SurveyId.toString(),
        SurveyAnswerTypeId: props.currentQuestion.SurveyAnswerTypeId.toString(),
        Question: props.currentQuestion.Question
      };
      setQuestion(newP);
    } else
      setQuestion({
        SurveyQuestionId: "",
        SurveyId: props.surveyId,
        SurveyAnswerTypeId: "",
        Question: "",
      });

        setRefreshAnswers((prev) => prev + 1);
        setSelectedAnswer(null);
        setShowEditAnswer(false);
    }, [props.currentQuestion]);

    const handleOnSelectAnswer = (answer) => {
        setShowEditAnswer(true);
        setSelectedAnswer({
            SurveyAnswerConfigId: answer.surveyAnswerConfigId,
            SurveyQuestionId: answer.surveyQuestionId,
            AnswerOrder: answer.answerOrder,
            Answer: answer.answer,
            IsRight: answer.isRight,
            IsImage: answer.isImage,
            StepAnswer: answer.stepAnswer
        });
    };
    const handleOnNewAnswer = () => {
        setSelectedAnswer(null);
        setShowEditAnswer(true);
    };
    const handleOnCancelEditAnswer = () => {
        setSelectedAnswer(null);
        setShowEditAnswer(false);
    };
    const handleOnSaveEditAnswer = () => {
        setRefreshAnswers((prev) => prev + 1);
        setShowEditAnswer(false);
    };

    return (
        <div>
            {question.SurveyAnswerTypeId != 1 ? (
                <div>
                    <div className="mb-3">
                        {!showEditAnswer && showEditAnswerMulti && (
                            <span className="pl-2 link_action" onClick={handleOnNewAnswer}>
                                <i className="fas fa-plus"></i> Agregar respuesta
                            </span>
                        )}
                    </div>
                    <div>
                        {showEditAnswer && (
                            <EditAnswer
                                surveyQuestionId={question.SurveyQuestionId}
                                SurveyAnswerTypeId={question.SurveyAnswerTypeId}
                                currentAnswer={selectedAnswer}
                                surveyQuestions={props.surveyQuestions}
                                onCancelEdit={handleOnCancelEditAnswer}
                                onSave={handleOnSaveEditAnswer}
                            />
                        )}
                    </div>
                    <div>
                        <SearchAnswer
                            surveyQuestionId={question.SurveyQuestionId}
                            surveyAnswerTypeId={question.SurveyAnswerTypeId}
                            onSelectAnswer={handleOnSelectAnswer}
                            setShowEditAnswerMulti={setShowEditAnswerMulti}
                            refresh={refreshAnswers}
                        />
                    </div>
                </div>
            ) : (
                <div className="box_empty">
                    <span>
                        <i className="fas fa-exclamation-triangle"></i>
                    </span>
                    <h6 className="text fnt_medium">Pregunta abierta</h6>
                </div>
            )}
        </div>
    );
}
