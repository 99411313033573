import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import "moment/locale/es";
import { chatService } from "./../../services/chat.service";
import { Searches } from "./searches";
import { TtlsChats } from "./ttlsChats";
import { AvatarChats } from "./avatarChats";

export function ListChats({ showChat, chatNotification, setNotifications }) {
    const [chats, setChats] = useState([]);
    const [chatsSearch, setChatsSearch] = useState([]);

    useEffect(() => {
        chatService.getChats().then(result => {
            setChats(result);
            setChatsSearch(result);
            const unread = result.filter(item => item.unread > 0);
            setNotifications(unread.map(i => i.contactId));
        });
    }, []);

    useEffect(() => {
        if (Object.keys(chatNotification).length > 0) {
            const filteredChat = chats.find(item => item.contactId == chatNotification.contactId);
            if (filteredChat) {
                chatNotification.name = filteredChat.name;
                chatNotification.unread += filteredChat.unread;
                chatNotification.image = filteredChat.image;
            }

            const filteredChats = chats.filter(item => item.contactId != chatNotification.contactId);
            if (chatNotification.unread > 0)
                filteredChats.unshift(chatNotification);

            setChats(filteredChats);
            setChatsSearch(filteredChats);
        }
    }, [chatNotification]);

    const handleSearch = (text) => {
        if (text) {
            text = text.toUpperCase();
            let chatFiltered = chats.filter((item) =>
                item.name.toUpperCase().includes(text)
            );
            if (chatFiltered.length > 0) {
                let contactsFiltered = Object.assign([], chatFiltered);
                setChatsSearch(contactsFiltered);
                return;
            }

            setChatsSearch([]);
        } else setChatsSearch(chats);
    };

    return (
        <div className="sections_chat active_sec wrapper_list_chats">
            <div className="p-3">
                <TtlsChats title="Chats" />
                <Searches placeHolder="Buscar chat" handleSearch={handleSearch} />

                <TtlsChats title="Recientes" />

                {chatsSearch.map(item =>
                    <div className={`box_item_chat cursor ${item.unread > 0 && "new-mnj"}`} key={item.contactId} onClick={() => showChat(item)} title={item.name}>
                        <AvatarChats img={item.image} titleImg={item.name} />
                        <div className="info_item_chat" >
                            <div className="name_chat text fnt_book">
                                {item.name}
                            </div>
                            <div className="time"><Moment format="hh:mm a">{item.lastMessageDate}</Moment></div>
                        </div>
                    </div>)
                }
            </div>
        </div>
    );
}
