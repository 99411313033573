import { callApiNoBlocking } from './service'

export const chatService = {
    sendMessage,
    getContacts,
    getChats,
    getMessages,
    updateRead,
    managementGroup,
    getGroups
}

async function getContacts() {
    return callApiNoBlocking('/chats/contacts', 'GET').then((result) => {
        return result;
    }).catch((error) => {
        console.log(error)
        return [];
    });
}

async function sendMessage(chatMessage) {
    return callApiNoBlocking('/chats/sendmessage', 'POST', chatMessage);
}

async function getChats() {
    return callApiNoBlocking('/chats', 'GET').then((result) => {
        return result;
    }).catch((error) => {
        console.log(error)
        return [];
    });
}

async function getMessages(receiverId, dateTime) {
    return callApiNoBlocking(`/chats/messages/${receiverId}/${dateTime ? dateTime : ""}`, 'GET').then((result) => {
        return result;
    }).catch((error) => {
        console.log(error)
        return [];
    });
}

async function updateRead(receiverId) {
    return callApiNoBlocking(`/chats/updateread/${receiverId}`, 'PUT');
}


async function managementGroup(group) {
    return callApiNoBlocking("/chats/groups", 'POST', group);
}

async function getGroups() {
    return callApiNoBlocking("/chats/groups", 'GET')
        .then(result => result)
        .catch((error) => {
            console.log(error)
            return [];
        });
}