import React, { useRef, useState } from 'react';
import { Col, Form, FormGroup, Label, Input } from "reactstrap";
import useInputSingleForm from '../../components/CustonHooks/inputFormHook';
import { SelectCustom } from '../../components/Helpers/SelectCustom';
import SearchCompanyEventType from './searchCompEventType';
import { companyEventTypeService } from '../../services/companyEventType.service';
import { useSelector } from 'react-redux';

export function AdminCompanyEventType() {
	const companyId = useSelector(state => state.userSettings.companyId)

    const gridRef = useRef();
    const [showEdit, setShowEdit] = useState(false);
    let companyEventType = { CompanyEventTypeId: 0, EventTypeId: '', IsRewarded: false, Reward: 0, CompanyEventTypeNotificationId: 0, IsNotification: false }
    const onSave = () => {
        let model = {
            CompanyEventTypeId: parseInt(inputs.CompanyEventTypeId),
            EventTypeId: parseInt(inputs.EventTypeId),
            CompanyEventTypeNotificationId: parseInt(inputs.CompanyEventTypeNotificationId),
            IsRewarded: Boolean(inputs.IsRewarded),
            Reward: parseInt(inputs.Reward),
            IsNotification: Boolean(inputs.IsNotification),
            NotificationMessage: inputs.NotificationMessage,
            CompanyId: companyId
        }

		if (parseInt(inputs.CompanyEventTypeId) === 0) {
			companyEventTypeService.createCompanyActionType(model).then((result) => {
				gridRef.current.refreshDataGrid();

			});
		} else {
			companyEventTypeService.updateCompanyActionType(model).then((result) => {
				gridRef.current.refreshDataGrid();
			});
		}
		setInputs({});
		setShowEdit(false);
	};
	const { inputs, handleInputChange, handleSubmit, setInputs } = useInputSingleForm(onSave, companyEventType);

	const handleOnNewCompanyEvent = () => {
		setInputs(companyEventType);
		setShowEdit(true);
	};

	const handleOnCancel = () => {
		setInputs({});
		setShowEdit(false);
	}

	const handleRowSelect = (gridItem) => {
		setShowEdit(true);
		setInputs({
			CompanyEventTypeId: gridItem.companyEventTypeId,
			EventTypeId: gridItem.eventTypeId,
			CompanyEventTypeNotificationId: gridItem.companyEventTypeNotificationId,
			IsRewarded: gridItem.isRewarded,
			Reward: gridItem.reward,
			IsNotification: gridItem.isNotification,
			NotificationMessage: gridItem.notificationMessage,
			CompanyId: gridItem.companyId,
			Active: gridItem.active
		});
	}

    return (
        <div className="wrapper_company_event_type">
            <div className="mb-4 mt-4 divtop ">
                <div className="box_ttl_opt no_reverse">
                    <h3 className="mb-0">Lista de tipos de evento</h3>
                    <div className="box_actions ">
                        <button type="button" className="btn secundary mr-2" onClick={handleOnNewCompanyEvent}><i className="fas fa-plus"></i> Agregar tipo de evento</button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {showEdit ?
                        <Form onSubmit={handleSubmit}>
                            <fieldset className='mt-4 mb-4'>
                                <legend className="text fnt_medium">{inputs.CompanyEventTypeId == 0 ? "Nuevo tipo de evento" : "Editar tipo de evento"}</legend>

                                <div className="row">
                                    <div className="col-md-3">
                                        <label htmlFor="Active">¿Tiene recompensa?</label>

                                        <div className="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                onChange={handleInputChange}
                                                checked={inputs.IsRewarded}
                                                name="IsRewarded"
                                                id="Active"
                                            />
                                            <label className="custom-control-label" htmlFor="Active">Si, tiene recompensa</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <FormGroup>
                                            <Label htmlFor="EventType">Tipo de evento</Label>
                                            <SelectCustom selectName="EventTypeId" handleOnChange={handleInputChange} selectValue={inputs.EventTypeId}
                                                method={inputs.CompanyEventTypeId == 0 ? "EventType/Filter" : "EventType"} textField="eventName" valueField="eventTypeId" disabled={inputs.CompanyEventTypeId == 0 ? false : true} required />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-4">
                                        <FormGroup>
                                            <Label htmlFor="Reward">Puntos</Label>
                                            <Input name="Reward" placeholder="Puntos" onChange={handleInputChange} value={inputs.Reward} required={inputs.IsRewarded ? true:false } />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <label htmlFor="IsNotification">¿Genera notificación?</label>

                                        <div className="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                onChange={handleInputChange}
                                                checked={inputs.IsNotification}
                                                name="IsNotification"
                                                id="IsNotification"
                                            />
                                            <label className="custom-control-label" htmlFor="IsNotification">Si, genera notificación</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <FormGroup>
                                            <Label htmlFor="NotificationMessage">Texto de la notificación</Label>
                                            <Input name="NotificationMessage" placeholder="NotificationMessage" onChange={handleInputChange} value={inputs.NotificationMessage} required={inputs.isNotification ? true : false} ></Input>
                                        </FormGroup>
                                    </div>
                                </div>
                                <div>
                                    <button className="btn minimum secundary ml-1 mr-1" type="button" onClick={handleOnCancel}>Cancelar</button>
                                    <input className="btn minimum primary ml-1 mr-1" type="submit" value="Guardar" />
                                </div>
                            </fieldset>
                        </Form> : null}
                </div>
            </div>
            <SearchCompanyEventType handleRowSelect={handleRowSelect} ref={gridRef} />
        </div>
    );
}