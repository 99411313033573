import React, { useEffect, useState } from 'react';
import { useQuestion } from '../../services/surveyQuestion.service';

export function SearchQuestion(props) {
    const [questionsData, setQuestionsData] = useState({ questions: [] });
    const handleDelete = (data) => {
        useQuestion.deleteQuestion(data).then((result) => { getQuestions(props.survey); });
    };
    const handleSelectQuestion = (data) => {
        if (props.onSelectQuestion)
            props.onSelectQuestion(data);
    };

    useEffect(() => {
        getQuestions(props.survey);
    }, [props.refresh]);

    async function getQuestions(survey) {
        if (survey.surveyId != "")
            useQuestion.getQuestions(survey).then((result) => {
                if (result) {
                    setQuestionsData({ questions: result });
                    props.setSurveyQuestions({ result });
                }
            });
    }

    return (
        <div className="table-responsive">
            <table className="wrapper_table table table-hover table-sm">
                <thead>
                    <tr>
                        <th className="min_width">Pregunta</th>
                        <th>Tipo de pregunta	</th>
                        <th>Status	</th>
                        <th>Acciones	</th>
                    </tr>
                </thead>
                <tbody>
                    {questionsData.questions.map(question => (
                        <tr key={question.surveyQuestionId}>
                            <td> <span className="link_data" onClick={() => handleSelectQuestion(question)}>{question.question}</span>		</td>
                            <td>{question.answerType}</td>
                            <td>{question.estatus}</td>
                            <td className="text-center">
                                <span className="icon_tables" onClick={() => handleDelete(question)}>
                                    <i className="far fa-trash-alt"></i>
                                </span>
                                <span className="icon_tables" onClick={() => handleSelectQuestion(question)}>
                                    <i className="fas fa-pen"></i>
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    );
}