import React, { useState, useEffect } from 'react';
import './userActivity.scss'
import { backendService } from './../../services/backend.service'
import Moment from 'react-moment'
import 'moment/locale/es';

export function UserActivity() {
	const [userActivity, setUserActivity] = useState([])

	useEffect(() => {
		backendService.userProfileActivity(false)
			.then((data) => {
				setUserActivity(data)
			});
	}, [])

	return (
		<div className="content_user_activity card shadows">
			<h5 className="text fnt_medium"><span><i className="fas fa-history"></i></span> Actividades del usuario</h5>
			<hr className='mt-0' />
			<div className="content_history_activity">
				<div className="box_ttls_activity">
					<div className="text fnt_medium">Todo</div>
					<div className="text fnt_medium">Historial</div>
				</div>
				<div className="box_list_activity">
					{
						userActivity.map(item =>
							<div className="item_list_activity">
								<div>
									<span className="ico_Activity">
										<i className="fas fa-pen"></i>
									</span>
								</div>
								<div className="item_description">
									<div className="name_activity">Se finalizo {item.type} "{item.name}"</div>
									<div className="data_activity"><Moment locale="es" format="DD [de] MMMM [de] YYYY hh:mm [hrs]">{item.expirationDate}</Moment></div>
								</div>
							</div>)
					}

				</div>
			</div>
		</div>

	)
}