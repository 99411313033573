import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { productFilterService } from '../../services/backoffice/rewardProductFilter.service';
import { useDispatch } from 'react-redux';
import swal from "sweetalert"

export function SearchProductFilter(props) {
    const dispatch = useDispatch();
    const [rewardProductFilterData, setRewardProductFilterData] = useState({ rewardProductFilter: [] });
    const handleDelete = (data) => {
        swal({
            title: "¿Estás seguro que deseas borrar esta recompensa?",
            icon: "warning",
            dangerMode: true,
            buttons: ["Cancelar", "Si, aceptar"]
        })
            .then((willDelete) => {
                if (willDelete) {
                    productFilterService.deleteProductFilter(data).then((result) => {  getRewardProductFilters(); });
                }
            });
    };

    useEffect(() => {
        getRewardProductFilters();
    }, [props.refresh]);

    async function getRewardProductFilters() {
        productFilterService.getAll().then((result) => {
            if (result) {
                // debugger;
                setRewardProductFilterData({ rewardProductFilter: result });
            }
        });
    }
    return (
        <div className="table-responsive card shadows p-3">
            <table className="wrapper_table table table-hover table-sm">
                <thead>
                    <tr>
                        <th className="text fnt_medium">ID </th>
                        <th className="text fnt_medium min_width">Producto </th>
                        <th className="text fnt_medium">Sucursal </th>
                        <th className="text fnt_medium">Grupo </th>
                        <th className="text fnt_medium">Categoría </th>
                        <th className="text fnt_medium">Stock </th>
                        <th className="text fnt_medium">Puntos</th>
                        <th className="text fnt_medium">Activo</th>
                    </tr>
                </thead>
                <tbody>
                    {rewardProductFilterData.rewardProductFilter.map(pf => (
                        <tr key={pf.rewardProductFilterId}>
                            <td>
                                <Link className="link_data" to={"/detail-product-filter/" + pf.rewardProductFilterId}>{pf.rewardProductFilterId}</Link>
                            </td>
                            <td>
                                <Link className="link_data" to={"/detail-product-filter/" + pf.rewardProductFilterId}>{pf.productDescription}</Link>
                            </td>
                            <td>
                                {pf.branchOfficeName == null ? "<Todas>" : pf.branchOfficeName}
                            </td>
                            <td>
                                {pf.departmentName == null ? "<Todos>" : pf.departmentName}
                            </td>
                            <td>
                                {pf.roleName == null ? "<Todos>" : pf.roleName}
                            </td>
                            <td>
                                {pf.filterStock}
                            </td>
                            <td>
                                {pf.points}
                            </td>
                            <td>{pf.active ? "Activo" : "Inactivo"}</td>
                            <td>
                                <span className="icon_tables" onClick={() => handleDelete(pf.rewardProductFilterId)}>
                                    <i className="far fa-trash-alt"></i>
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}