import React from "react";

export function AvatarChats({ img, titleImg }) {
  return (
    <div className="box_avatar_user in_chat">
      <span className="avatar">
        <img
          src={
            img
              ? img
              : "https://storagervadmindev.blob.core.windows.net/ctnprimecontentmx/BaseImage/Default-avatar.jpg"
          }
          alt="Usuarios en Kuimby"
          title={titleImg}
        />
      </span>

      {/* ====================
     CLASES COLOR STATUS
     ======================
    .active
    .occupied
    .disconnected */}
      {/*<span className="status_color active"></span>*/}
    </div>
  );
}
