import React, { useRef, useEffect, useState } from "react";
import { chatService } from "./../../services/chat.service";
import { TtlsChats } from "./ttlsChats";
import { AvatarChats } from "./avatarChats";
import moment from "moment";

export function NewGroup({ isOpenGroup, lastOpenGroup, updateGroup }) {
    const [contacts, setContacts] = useState([]);
    const [contactsSearch, setContactsSearch] = useState([]);
    const [form, setForm] = useState({ name: "", expirationDate: "", contacts: [] });
    const [infoGroupSec, secInfoGroupSec] = useState("1");
    const [findContact, setFindContact] = useState("");
    const toggle = (sec) => {
        if (infoGroupSec !== sec) secInfoGroupSec(sec);
    };

    useEffect(() => {
        chatService.getContacts().then((result) => {
            let resultContacts = [];
            result.forEach((item) => {
                resultContacts = [...resultContacts, ...item.users.map((user) => user)];
            });
            setContacts(resultContacts);
            setContactsSearch(resultContacts);
        });
    }, []);

    useEffect(() => {
        if (updateGroup) {
            setForm({ name: updateGroup.name, expirationDate: moment(updateGroup.expirationDate).format("YYYY-MM-DD"), contacts: updateGroup.contacts });
        }
    }, [updateGroup]);

    const handleSearch = (text) => {
        setFindContact(text)
        if (text) {
            text = text.toUpperCase();
            let contactFiltered = contacts.filter((item) =>
                item.name.toUpperCase().includes(text)
            );
            if (contactFiltered.length > 0) {
                let contactsFiltered = Object.assign([], contactFiltered);
                setContactsSearch(contactsFiltered);
                return;
            }

            setContactsSearch([]);
        } else setContactsSearch(contacts);
    };

    const handelForm = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    }

    const handleCheckbox = (e) => {
        if (e.target.checked) setForm({ ...form, contacts: [...form.contacts, e.target.name] });
        else
            setForm({
                ...form,
                contacts: [
                    ...form.contacts.filter((item) => item != e.target.name),
                ]
            });
    };

    const onSubmit = () => {
        if (form.name && form.contacts.length > 0) {
            chatService
                .managementGroup({ Id: updateGroup ? updateGroup.id : null, Name: form.name, Image: "", Contacts: form.contacts, ExpirationDate: form.expirationDate ? form.expirationDate : null })
                .then(() => {
                    setForm({ name: "", expirationDate: "", contacts: [] });
                    toggle(1);
                    lastOpenGroup()
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    return (
        <div className={`sec_new_group ${isOpenGroup}`}>
            {
                {
                    1: (
                        <div className="cont_new_group__info">
                            {/*<div className="drawer_avatar_gp">*/}
                            {/*  <span className="toback" onClick={props.lastOpenGroup}>*/}
                            {/*    <i className="fas fa-chevron-left"></i>*/}
                            {/*  </span>*/}
                            {/*  <AvatarChats />*/}
                            {/*  <label*/}
                            {/*    htmlFor="file-upload"*/}
                            {/*    className="custom-file-upload icons"*/}
                            {/*  >*/}
                            {/*    <ion-icon name="camera-outline"></ion-icon>*/}
                            {/*  </label>*/}
                            {/*  <input*/}
                            {/*    id="file-upload"*/}
                            {/*    type="file"*/}
                            {/*    name="ProfileImage"*/}
                            {/*    accept="image/*"*/}
                            {/*  />*/}
                            {/*  <button type="submit" className="icons ico_save">*/}
                            {/*    <i className="fas fa-check"></i>*/}
                            {/*  </button>*/}
                            {/*</div>*/}
                            <div className="drawer_info_gp">
                                <div className="form-group">
                                    <TtlsChats title="Asunto del grupo" />
                                    <input
                                        type="text"
                                        className="form-control mt-1"
                                        aria-describedby="asuntoGrupo"
                                        placeholder="Agrega un asunto al grupo"
                                        name="name"
                                        value={form.name}
                                        onChange={handelForm}
                                    />
                                </div>
                                <div className="form-group">
                                    <TtlsChats title="Vigencia del grupo" />
                                    <input
                                        className="form-control mt-1"
                                        type="date"
                                        name="expirationDate"
                                        value={form.expirationDate}
                                        onChange={handelForm}
                                    />
                                </div>
                            </div>
                            <div className="text-center ">
                                <button
                                    className="btn primary minimum "
                                    onClick={() => toggle(2)}
                                    disabled={!form.name}
                                >
                                    Siguiente <ion-icon name="arrow-forward-outline"></ion-icon>
                                </button>
                            </div>
                        </div>
                    ),
                    2: (
                        <div className="cont_new_group__contact">
                            <div className="box_inputs">
                                <div className="to_back_gr" onClick={() => toggle(1)}>
                                    <i className="fas fa-chevron-left"></i>
                                </div>
                                <div className="box_search_contact">
                                    <label className="text fnt_medium">
                                        Añadir participantes
                  </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Buscar contacto"
                                        onChange={(e) => handleSearch(e.target.value)}
                                        value={findContact}
                                    />
                                </div>

                                <span className="ico_form">
                                    <small className="text fnt_medium">
                                        {form.contacts.length}/{contactsSearch.length}
                                    </small>
                                </span>
                            </div>

                            <div className="box_list_contact">
                                {contactsSearch.map((user) => (
                                    <div
                                        className="inputGroup"
                                        key={user.userId}
                                        title={user.name}
                                    >
                                        <input
                                            id={user.userId}
                                            name={user.userId}
                                            type="checkbox"
                                            onChange={handleCheckbox}
                                            checked={form.contacts.includes(user.userId)}
                                        />
                                        <label htmlFor={user.userId}>
                                            <AvatarChats img={user.urlImage} />
                                            <div className="name_chat text fnt_book">{user.name}</div>
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <div className="box_btns_options ">
                                <button
                                    className="btn primary minimum "
                                    disabled={!form.name || form.contacts.length == 0}
                                    onClick={onSubmit}
                                >
                                    {updateGroup ? "Actualizar" : "Crear"} grupo
                </button>
                            </div>
                        </div>
                    ),
                }[infoGroupSec]
            }
        </div>
    );
}
