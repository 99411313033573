import React, { useState, useEffect } from 'react';
import { Table } from "reactstrap";
import { companyPostService } from '../../services/companyPost.service';
import { storageService } from '../../services/storage.service';

export function CompanyPostAttachment(props) {

	const [localStorage, setlocalStorage] = useState([]);

	async function getAttachments() {

		if (props.CompanyPostId != null && props.CompanyPostId > 0) {
			console.log(props);
			companyPostService.getCompanyPostFilesById(props.CompanyPostId).then((result) => {
				if (result != null && result.files != null) {
					setlocalStorage(result.files);
				}
			});
		}
	}

	useEffect(() => {
		getAttachments();
	}, [props.CompanyPostId]);

	const handleDelete = (event, data) => {

		storageService.deleteStorage({ EntityId: data.entityId, EntityTypeId: data.entityTypeId, Sequence: data.sequence }).then((result) => {
			setlocalStorage(
				localStorage.filter(function (storage) {
					return storage.sequence !== data.sequence;
				})
			);
		});
		event.stopPropagation();
	};

	return (
		<span >
			{localStorage != null && localStorage.length > 0 ?
				<span className="files">
					{localStorage.map(item => (
						<span>
							<a
								className="item_file"
								key={item.entityId + '-' + item.entityTypeId + '-' + item.sequence}
								download={item.fileName} href={`data:application/octet-stream;base64,${item.file}`}
							>

								<span className="ico_file"><i className="far fa-file-alt"></i></span>
								<small>{item.fileName}</small>
							</a>
							{props.IsDelete != null && props.IsDelete ?
								<span className="trans btn_delete" onClick={(event) => handleDelete(event, item)}>
									<i className="far fa-trash-alt"></i>
								</span>
								: null}
						</span>
					))}
				</span>
				: null}
		</span>
	);
}
