import React, { useState, useEffect } from "react";
import { FormGroup, Label } from "reactstrap";
import { SelectCustom } from "../../components/Helpers/SelectCustom";
import { helperService } from "../../services/helper.service";
import { userProfileService } from "../../services/backoffice/userProfile.service";
import useInputSingleForm from "../../components/CustonHooks/inputFormHook";
import MultiSelect from "react-multi-select-component";

import "rsuite/dist/styles/rsuite-default.css";

export function SelectUsers({ selectUser, title, filter, usersFilter }) {
  let configInit = { Departments: [], JobRoleId: 0 };
  const [departaments, setDepartaments] = useState([]);
  const [userSelec, setUserSelec] = useState([]);
  const [dataUsers, setDataUsers] = useState([]);
  const { inputs, handleInputChange, setInputs } = useInputSingleForm(
    null,
    configInit
  );

  useEffect(() => {
    getDepartaments();
  }, []);

  useEffect(() => {
    handleOnSearch();
  }, [usersFilter]);

  const handleOnSearch = () => {
    if (inputs.Departments.length > 0) {
      let model = {
        Departments: inputs.Departments.map((e) => e.value).join(","),
        JobRoleId: parseInt(inputs.JobRoleId === "" ? 0 : inputs.JobRoleId),
      };
      userProfileService.getUsersByFilter(model).then((result) => {
        if (result) {
          if (filter) {
            let users = result;
            if (usersFilter.length > 0) {
              usersFilter.map((user) => {
                users = users.filter((item) => item.userProfileId != user.id);
                setDataUsers(users);
              });
            } else {
              setDataUsers(users);
            }
          } else {
            setDataUsers(result);
          }
        }
      });
    }
  };

  const handleSelectUser = (event, data) => {
    let localUsers = [];
    data.check = event.target.checked;
    if (data.check) {
      localUsers = [
        ...userSelec,
        {
          id: data.userProfileId,
          name: `${data.middleName} ${data.lastName} ${data.firstName}`,
          department: data.department.departmentName,
        },
      ];
      setUserSelec(localUsers);
    } else {
      localUsers = userSelec.filter((item) => item.id != data.userProfileId);
      setUserSelec(localUsers);
    }
    selectUser(localUsers);
    event.stopPropagation();
  };

  const handleSelectUserAll = (event) => {
    setUserSelec([]);
    let localUsers = [];
    dataUsers.map((data) => {
      if (event.target.checked && !data.check) {
        userSelec.push({
          id: data.userProfileId,
          name: `${data.middleName} ${data.lastName} ${data.firstName}`,
          department: data.department.departmentName,
        });
        setUserSelec([...userSelec]);
        localUsers = userSelec;
      }

      data.check = event.target.checked;
    });

    selectUser(localUsers);
  };

  const getDepartaments = () => {
    helperService.getGenericCatalog("/backoffice/Department").then((result) => {
      if (result)
        setDepartaments(
          result.map((item) => ({
            label: item.departmentName,
            value: item.departmentId,
          }))
        );
      else setDepartaments([]);
    });
  };

  return (
    <div className="row align-items-center mt-4">
      <div className="col-12">
        <h5 className="text fnt_medium">{title}</h5>
        <hr />
      </div>
      <div className="col-md-4">
        <FormGroup>
          <Label for="DepartmentId">Grupos</Label>
          <MultiSelect
            name="DepartmentId"
            options={departaments}
            value={inputs.Departments}
            onChange={(e) => setInputs({ ...inputs, Departments: e })}
            overrideStrings={{
              allItemsAreSelected: "Todos los grupos.",
              clearSearch: "Borrar búsqueda",
              noOptions: "Sin opciones",
              search: "Buscar",
              selectAll: "Seleccionar todo",
              selectSomeItems: "Selecciona una opción",
            }}
          />
        </FormGroup>
      </div>
      <div className="col-md-4">
        <FormGroup>
          <Label for="JobRoleId">Categorías</Label>
          <SelectCustom
            selectName="JobRoleId"
            handleOnChange={handleInputChange}
            selectValue={inputs.JobRoleId}
            method="JobRole"
            textField="name"
            valueField="jobRoleId"
            defaultOption="Todos"
          />
        </FormGroup>
      </div>
      <div className="col-md-4">
        <button
          type="button"
          className="btn secundary minimum"
          onClick={handleOnSearch}
        >
          Consultar
        </button>
      </div>

      <div className="col-12">
        <div className="table-responsive mt-4">
          <table className="wrapper_table table table-sm table-hover">
            <thead className="thead-dark">
              <tr>
                <th className="text fnt_medium">No. Usuario</th>
                <th className="text fnt_medium">Nombre de Usuario</th>
                <th className="text fnt_medium">Grupo</th>
                <th className="text fnt_medium text-center">
                  {dataUsers.length > 0 ? (
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        id="checkboxAll"
                        className="custom-control-input"
                        onClick={handleSelectUserAll}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="checkboxAll"
                      >
                        Seleccionar
                      </label>
                    </div>
                  ) : (
                    "Seleccionar"
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {dataUsers.map((item) => (
                <tr key={item.userProfileId}>
                  <td>{item.employeeNo}</td>
                  <td>
                    {item.middleName} {item.lastName} {item.firstName}
                  </td>
                  <td>{item.department.departmentName}</td>
                  <td className="text-center">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`check-${item.userProfileId}`}
                        onClick={(event) => handleSelectUser(event, item)}
                        checked={item.check}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`check-${item.userProfileId}`}
                      >
                        {" "}  &nbsp;
                      </label>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
