import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { companyPostService } from "../services/companyPost.service";
import R_C from "../assets/imgs/R_C.png";

export function BoxBusiness() {
  const [selectedItem, setSelectedItem] = useState({});
  useEffect(() => {
    getLastPost();
  }, []);

  async function getLastPost() {
    companyPostService.getLastCompanyPostFront().then((result) => {
      if (result) {
        var data = {
          Title: result.data.title,
          Count: result.count,
          FilePath: "",
        };
        if (result.count > 0) data.FilePath = result.files[0].path;
        setSelectedItem(data);
      }
    });
  }

  return (
    <div className="box_business drop_home shadows bradius">
      <h4 className="text fnt_medium">Blog </h4>
      <div className="ttls_drop">
        {selectedItem ? (
          <h5 className="text">{selectedItem.Title}</h5>
        ) : (
          <h5 className="text">Red Companies</h5>
        )}
        <Link className="more" to="/our-company">
          <span>Ver más</span> <i className="fas fa-angle-right"></i>
        </Link>
      </div>
      <div className="content_drop">
        <div className="">
          {selectedItem && selectedItem.Count > 0 ? (
            <img src={selectedItem.FilePath} alt={selectedItem.Title} />
          ) : (
            <img src={R_C} alt="Red Companies" />
          )}
        </div>
      </div>
    </div>
  );
}
