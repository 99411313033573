import React, { useState, useEffect } from "react";
import "./surveys.scss";
import { useSurveyUserProfile } from "../../services/surveyUserProfile.service";
// import { backendService } from "../../services/backend.service";

import BoxSurveys from "./boxSurveys";
// import Moment from "react-moment";
// import "moment/locale/es";
// import { useSelector } from "react-redux";

export function SurveyView(props) {
  const [selectedSurvey, setSelectedSurvey] = useState();
  const [surveysData, setSurveysData] = useState({
    pendingSurveys: [],
    resolvedSurveys: [],
  });

  useEffect(() => {
    getSurveys();
  }, [props.refresh]);

  const getSurveys = async () => {
    useSurveyUserProfile.getSurveysUserProfile().then((result) => {
      if (result) {
        setSurveysData(result);
      }
    });
  };

  return (
    <section className={`wrapper_answering}`}>
      <div className="sec_answering">
        <BoxSurveys />
      </div>
      {/* <div className="sec_answering">
          {selectedSurvey ? (
            <BoxSurveys
              survey={selectedSurvey}
              surveys={surveysData.pendingSurveys}
            />
          ) : (
            <div className="text-center mt-4">
              <div>
                <span className="ico_round">
                  <i className="fas fa-pencil-alt"></i>
                </span>
              </div>
            </div>
          )}
        </div> */}
    </section>
  );
}
