import React, { useState, useEffect } from "react";
import { useQuestion } from "../../services/surveyQuestion.service";

export function SurveyAnswerList(props) {
  const [answerList, setAnswerList] = useState(0);
  const [answerType, setAnswerType] = useState(0);

  useEffect(() => {
    getAnswerList(props.questionId);
  }, [props.questionId]);
  useEffect(() => {
    setAnswerType(props.surveyAnswerTypeId);
  }, [props.surveyAnswerTypeId]);

  const getAnswerList = async (questionId) => {
    useQuestion.getQuestionAnswerList(questionId).then((result) => {
      if (result) {
        setAnswerList(result);
      }
    });
  };

  return (
    <div className="table-responsive ">
      <table className="wrapper_table table table-hover table-sm">
        <thead className="thead-dark">
          <tr>
            <th className="text fnt_medium min_width">Usuario </th>
            {
              answerType == 5 ?
              <th className="text fnt_medium">Imagen</th>
              :
              <th className="text fnt_medium">Respuesta</th>
            }
          </tr>
        </thead>
        <tbody>
          {answerList &&
            answerList.map((answer) => (
              <tr key={answer.surveyUserProfileAnswerId}>
                <td className="text-left">{answer.userName}</td>
                <td className="wrapper_result_img">
                  {answerType == 5 ? (
                    <img
                      className="opt_img_answer"
                      src={answer.answerText}
                      alt="Tu opinion en kuimby"
                    />
                  ) : (
                    <p>{answer.answerText}</p>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
