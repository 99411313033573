import React, { useEffect, useState } from "react";
import { chatService } from "./../../services/chat.service";
import { Searches } from "./searches";
import { TtlsChats } from "./ttlsChats";
import { AvatarChats } from "./avatarChats";
import { NewGroup } from "./newGroup";

export function ListGroups({ showChat }) {
    const [groups, setGroups] = useState([]);
    const [groupsSearch, setGroupsSearch] = useState([]);
    const [updateGroup, setUpdateGroup] = useState(null);

    const [openGroup, setOpenGroup] = useState("");
    const fOpenGroup = () => {
        setOpenGroup(openGroup === "" ? "open_new_group" : "");
    };

    useEffect(() => {
        if (openGroup == "") {
            chatService.getGroups().then((result) => {
                setGroups(result);
                setGroupsSearch(result);
            });
            setUpdateGroup(null);
        }
    }, [openGroup]);

    const handleSearch = (text) => {
        if (text) {
            text = text.toUpperCase();
            let groupFiltered = groups.filter((item) =>
                item.name.toUpperCase().includes(text)
            );
            if (groupFiltered.length > 0) {
                let groupsFiltered = Object.assign([], groupFiltered);
                setGroupsSearch(groupsFiltered);
                return;
            }

            setGroupsSearch([]);
        } else setGroupsSearch(groups);
    };

    const handleOpenChat = (group) => {
        const chat = {
            contactId: group.id,
            name: group.name,
            image: group.image,
            readOnly: false
        };
        showChat(chat);
    };

    const handleUpdateGroup = (group) => {
        setUpdateGroup(group);
        fOpenGroup();
    }

    return (
        <div className="sections_chat active_sec wrapper_list_chats gr p-3">
            <div className="box_groups">
                <TtlsChats title="Grupos" />
                <button className="btn-new-group" onClick={fOpenGroup}>
                    <ion-icon name="person-add-outline"></ion-icon>{" "}
                    <span>Crear grupo</span>
                </button>
            </div>
            <Searches placeHolder="Buscar grupo" handleSearch={handleSearch} />
            <div className="overflow_wpr">
                {groupsSearch.map((group) => (
                    <div
                        className="box_item_chat"
                        key={group.id}
                    >
                        <AvatarChats img={group.image} />
                        <div className="info_item_chat">
                            <div className="name_chat text fnt_book">{group.name}</div>
                            <div className="options">
                                <span className="opt_chat" onClick={() => handleUpdateGroup(group)}>
                                    <i className="fas fa-pencil-alt"></i>
                                </span>
                                <span className="opt_chat" onClick={() => handleOpenChat(group)}>
                                    <i className="fas fa-comment-dots"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <NewGroup isOpenGroup={openGroup} lastOpenGroup={fOpenGroup} updateGroup={updateGroup} />
        </div>
    );
}
