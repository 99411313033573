import React, { useState, useEffect } from 'react';
import ItemPayMethod from './itemPayMethod/itemPayMethod'
import './boxPayMethod.scss'
import { ecommerceService } from "../../services/ecommerce.service";

const BoxPayMethod = ({ onCloseClick }) => {
    const [ paymentMethods, setPaymentMethods ] = useState([]);

    useEffect(() => {
        ecommerceService.getPaymentMethods()
            .then((result => {
                // debugger
                setPaymentMethods(result);
            }));
    },[]);

    return (
        <div className="box_paymethod">
            {
                paymentMethods.map(method => {
                    // debugger
                    return <ItemPayMethod onCloseClick={onCloseClick} method={method} />
                })
            }
        </div>
    )
}

export default BoxPayMethod