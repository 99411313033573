import React, { useEffect, useState } from "react";
import useInputForm from "../../components/CustonHooks/FormHook";
import { useAnswerConfig } from "../../services/surveyAnswerConfig.service";

export function EditAnswer(props) {
    const onSave = () => {
        if (inputs.SurveyAnswerConfigId && inputs.SurveyAnswerConfigId > 0) {            
            useAnswerConfig
                .updateAnswerConfig({
                    SurveyAnswerConfigId: parseInt(inputs.SurveyAnswerConfigId),
                    SurveyQuestionId: parseInt(inputs.SurveyQuestionId),
                    AnswerOrder: parseInt(inputs.AnswerOrder),
                    Answer: inputs.Answer,
                    IsRight: inputs.IsRight,
                    IsImage: inputs.IsImage,
                    StepAnswer: inputs?.StepAnswer?parseInt(inputs.StepAnswer):0
                })
                .then((result) => {
                    if (props.onSave) props.onSave();
                });
        }
        else {
            useAnswerConfig
                .createAnswerConfig({
                    SurveyQuestionId: parseInt(inputs.SurveyQuestionId),
                    AnswerOrder: parseInt(inputs.AnswerOrder),
                    Answer: inputs.Answer,
                    IsRight: inputs.IsRight,
                    IsImage: inputs.IsImage,
                    StepAnswer: inputs?.StepAnswer?parseInt(inputs.StepAnswer):0
                })
                .then((result) => {
                    if (props.onSave) props.onSave();
                });
        }
    };
    const handleOnCancel = () => {
        if (props.onCancelEdit) props.onCancelEdit();
    };
    const [answer, setAnswer] = useState({
        SurveyAnswerConfigId: "",
        SurveyQuestionId: props.surveyQuestionId,
        AnswerOrder: "",
        Answer: "",
        IsRight: false,
        IsImage: false,
        StepAnswer: 0
    });
    const [stepOptions, setStepOptions] = useState([])
    const [visibleCheck, setVisibleCheck] = useState(true)
    const noCheck = [6]
    const { handleSubmit, handleInputChange, inputs, setInputs } = useInputForm(
        onSave,
        answer
    );

    useEffect(() => {
        let newP = {};
        if (props.currentAnswer) {
            newP = {
                SurveyAnswerConfigId: props.currentAnswer.SurveyAnswerConfigId.toString(),
                SurveyQuestionId: props.currentAnswer.SurveyQuestionId.toString(),
                AnswerOrder: props.currentAnswer.AnswerOrder.toString(),
                Answer: props.currentAnswer.Answer,
                IsRight: props.currentAnswer.IsRight,
                IsImage: props.currentAnswer.IsImage,
                StepAnswer: props.currentAnswer.StepAnswer
            };
            setAnswer(newP);
        } else
            setAnswer({
                SurveyAnswerConfigId: "",
                SurveyQuestionId: props.surveyQuestionId,
                AnswerOrder: "",
                Answer: "",
                IsRight: false,
                IsImage: false,
                StepAnswer: 0
            });
        if(noCheck.includes(parseInt(props.SurveyAnswerTypeId))){
            setVisibleCheck(false)
        } else {
            setVisibleCheck(true)
        }
    }, [props.currentAnswer]);

    useEffect(() => {
        var filterData = props.surveyQuestions.result.filter(item => item.surveyQuestionId>props.surveyQuestionId);
        setStepOptions(filterData)
    }, [props.surveyQuestionId]);

    return (
        <div className="content_new_question">
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <input
                        type="hidden"
                        name="SurveyAnswerConfigId"
                        onChange={handleInputChange}
                        value={inputs.SurveyAnswerConfigId}
                    />

                    <div className="col-md-4">
                        <div className="form-group mb-2">
                            <label for="">Orden</label>
                            <input
                                type="number"
                                className="form-control"
                                name="AnswerOrder"
                                min="1"
                                onChange={handleInputChange}
                                value={inputs.AnswerOrder}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="form-group mb-2">
                            <label for="">Respuesta</label>
                            <input
                                type="text"
                                className="form-control"
                                name="Answer"
                                onChange={handleInputChange}
                                value={inputs.Answer}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="answer_is_img"
                                name="IsImage"
                                onChange={handleInputChange}
                                checked={inputs.IsImage}
                            />
                            {
                                visibleCheck &&
                                    (props.SurveyAnswerTypeId != 5 ?
                                        <label className="custom-control-label" for="answer_is_img">
                                            ¿La respuesta es solo una imagen?
                                        </label>
                                        :
                                        <label className="custom-control-label" for="answer_is_img">
                                            Varias Imágenes
                                        </label>
                                    )
                            }
                        </div>
                    </div>
                    {
                        stepOptions.length > 0 &&
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="branchOfficeId">Salto</label>
                                    <select className="form-control" id="StepAnswer" name="StepAnswer" value={inputs.StepAnswer} onChange={handleInputChange}>
                                        <option value={0}></option>
                                        {
                                            stepOptions.filter(item => item.surveyQuestionId!=stepOptions[0].surveyQuestionId).map(item => <option key={item.surveyQuestionId} value={item.surveyQuestionId}> {item.question}</option>)
                                        }
                                        <option value={-1}>[Finalizar Encuesta]</option>
                                    </select>
                                </div>
                            </div>
                    }
                </div>

                <div className="mt-4 text-right">
                    <button
                        className="btn secundary minimum ml-1 mr-1"
                        type="button"
                        onClick={handleOnCancel}
                    >
                        Cancelar
                    </button>
                    <button className="btn primary minimum " type="submit">
                        Agregar
                    </button>
                </div>
            </form>
        </div>
    );
}
