
import React, { Component } from "react";
import Slider from "react-slick";
import { useQuestion } from "../../services/surveyQuestion.service";
import { AnswersSurvey } from "./answersSurvey";
import IcoPoll from "../../assets/imgs/iconos/ico_poll.png";

export default class BoxSurveys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      questionIndex: 1,
      questionCount: 0,
      questionIndexSave: -1,
      previousClick: false,
    };
    this.lastSave = 0;
  }

  componentWillMount() {
    this.getQuestions(this.props.survey);
    document.addEventListener("keydown", this._handleKeyDown);
    document.addEventListener("keyup", this._handleKeyDown);
    this.setState({ questionCount: this.props.survey.questionCount });
  }

  _handleKeyDown = (event) => {
    switch( event.key ) {
        case 'Alt':
            event.preventDefault()
            break;
        case 'Tab':
            event.preventDefault() 
            break;
        default: 
            break;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.getQuestions(nextProps.survey);
      this.setState({
        questionCount: nextProps.survey.questionCount,
        questionIndex: 1,
        questionIndexSave: -1,
      });
    }
  }

  getQuestions = async () => {
    var survey = this.props.survey;
    useQuestion.getFrontQuestions(survey).then((result) => {
      if (result) {
        this.setState({ questions: result });
      }
    });
  };

  setQuestionIndexSave = (value) => {
    this.setState({ questionIndexSave: value });
  };

  setPreviousClick = (value) => {
    this.setState({ previousClick: value });
  };

  setLastSave = (index) => {
    if (this.lastSave < index) {
      this.lastSave = index;
      this.slider.slickGoTo(index + 1);
    }
  };

  handleQuestionChange = (questionSave) => {
    this.setQuestionIndexSave(questionSave ? this.state.questionIndex - 1 : -1);
    if (!questionSave) {
      this.slider.slickPrev();
      this.setPreviousClick(true);
    }
  };

  next = () => {
    this.slider.slickNext();
  };

  previous = (id) => {
    let index = this.state.questions.findIndex(
      (x) => x.surveyQuestionId === id
    );
    this.slider.slickGoTo(index - 1);
  };

  getQuestionIndex = (id) => {
    let index = this.state.questions.findIndex(
      (x) => x.surveyQuestionId === id
    );
    this.slider.slickGoTo(index);
  };

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 200,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      beforeChange: (current, next) =>
        this.setState({ questionIndex: next + 1 }),
      adaptiveHeight: true,
      swipe: false,
    };

    const {
      questions,
      questionIndex,
      questionCount,
      questionIndexSave,
      previousClick,
    } = this.state;
    return (
      <div className="section_surveys">
        <div className="box_ttl_answ_component">
          <div className="data_info_answ w-100">
            <img
              className="img_icosurvey"
              src={IcoPoll}
              alt="Encuestas en kuimby"
            />
            <div className="ml-2 lheight">
              <h6 className="text fnt_medium mb-0 ">
                {this?.props?.survey?.title}
              </h6>
              <div className="mt-0 ">
                <small>{this?.props?.survey?.reward} puntos</small>
              </div>
            </div>
          </div>

          <div className="w-100 box__back__survey">
            <span
              className="ico__back__survey "
              onClick={() => window.location.reload()}
            >
              <ion-icon name="arrow-back-outline"></ion-icon>
              Salir
            </span>
            <div className="text fnt_medium quest">
              Pregunta <span>{questionIndex}</span>/<span>{questionCount}</span>
            </div>
          </div>
        </div>
        <div className="w-100">
          <Slider ref={(c) => (this.slider = c)} {...settings}>
            {questions.map((question, index) => (
              <div className="outline" key={question.surveyQuestionId}>
                <h5 className=" question">{question.question}</h5>
                <div className="answers">
                  <AnswersSurvey
                    index={index}
                    question={question}
                    setPreviousClick={this.setPreviousClick}
                    previousClick={previousClick}
                    getQuestionIndex={this.getQuestionIndex}
                    surveyUserProfileId={this.props.survey.surveyUserProfileId}
                    next={this.next}
                    previous={this.previous}
                    setQuestionIndexSave={this.setQuestionIndexSave}
                    questionIndexSave={questionIndexSave}
                    questionIndex={questionIndex}
                    questionCount={questionCount}
                    setLastSave={this.setLastSave}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="box_arrows">
          <span
            className="arrow lf"
            onClick={() => this.handleQuestionChange(false)}
          >
            <i className="fas fa-angle-left mr-2"></i> Anterior
          </span>
          <span
            className="arrow rg"
            onClick={() => this.handleQuestionChange(true)}
          >
            {questionIndex != questionCount ? "Siguiente" : "Finalizar"}{" "}
            <i className="fas fa-angle-right ml-2"></i>
          </span>
        </div>
      </div>
    );
  }
}