import React, { useState } from 'react'
import './ecommerce.scss'
import Moment from 'react-moment'
import 'moment/locale/es'
import { BoxListEcommerce } from './boxListEcommerce'
import PictureHelp from '../../assets/imgs/picture_audience.png'

export function Ecommerce(props) {
  const [activeAsideSurvey, setStateAsideSurvey] = useState('')

  const AsidePurchaseBox = () => {
    setStateAsideSurvey(activeAsideSurvey === '' ? ' is-show' : '')
  }

  return (
    <section className="wrapper_surveys wrapper_ecommerce padd">
      <div className="content_surveys ">
        <div className="row align-items-center mb-4">
          <div className="col"><h2 className="mb-0 text fnt_medium ">Tienda en línea</h2></div>
          <div className="col text-muted box_more_survey">
            <span className="d-none d-sm-block"><Moment locale="es" format="DD [de] MMMM  YYYY">{Date()}</Moment></span>
            <span className="btn_ellipsis" onClick={AsidePurchaseBox}><i className="fas fa-ellipsis-v"></i></span>
          </div>
        </div>
        <div className="content_ttls">
          <div className='minw'>
            <h4 className="text fnt_medium ttl mb-3 text-white">Bienvenido a la Tienda en línea</h4>
            <p className="text fnt_medium">Este es nuestro acceso a la tienda en línea.</p>
          </div>
          <div className="box_img_surveys">
            <img src={PictureHelp} alt="Encuestas" />
          </div>
        </div>

       <BoxListEcommerce />

       {/*       <p className="text-muted mt-4 line_height">*/}
       {/*           <small>La entrada a esta tienda es mediante la siguiente <a href="https://appmarketplacefrontmxprod.azurewebsites.net">liga</a></small>*/}
       {/*</p>*/}
      </div>




      {/*<aside className={`aside_lateral trans ${activeAsideSurvey}`}>*/}
      {/*  <span className="btn_ellipsis_close" onClick={AsidePurchaseBox}><i className="far fa-window-close"></i></span>*/}
      {/*  <div className="text-center">*/}
      {/*    <h3 className="text fnt_medium mb-1">Recompensas</h3>*/}
      {/*    */}{/* <h5 className="text-muted">Nombre</h5> */}

      {/*    */}{/* <div className="box_avatar_user">*/}{/*
      */}{/*      <span className="avatar">*/}{/*
      */}{/*        <img src={profile && profile.urlProfileImage} alt="NameUser" />*/}{/*
      */}{/*      </span>*/}{/*
      */}{/*    </div> */}

          
      {/*  </div >*/}
        
      {/*</aside>*/}
    </section>
  )
}