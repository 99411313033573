import React, { useEffect, useState, useRef } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { ListChats } from "./listChats";
import { ListGroups } from "./listGroups";
import { ListContact } from "./listContact";
import { ChatConversations } from "./chatConversations";
import { ProfileChat } from "./profileChat";
import { useSelector } from "react-redux";
import $ from "jquery";
import "./wrapperChats.scss";

export function WrapperChats(props) {
    const roleName = useSelector(state => state.userSettings.roleName);
    const [activeSec, setActiveSec] = useState("1");

    const toggle = (sec) => {
        if (activeSec !== sec) {
            setActiveSec(sec);
        }
    };

    const [openChat, setOpenChat] = useState("");
    const [icoOpen, setIcoOpen] = useState("resize-outline");
    const toogleOpenChat = () => {
        setOpenChat(openChat === "" ? "open" : "");
        setIcoOpen(
            icoOpen === "resize-outline" ? "remove-outline" : "resize-outline"
        );
        $("body").toggleClass("noScroll");
    };

    const [btnMenu, setBtnMenu] = useState("");
    const [showNavbar, setShowNavbar] = useState("");
    const funtMenu = () => {
        setBtnMenu(btnMenu === "" ? "visible_menu" : "");
        setShowNavbar(showNavbar === "" ? "show-navbar" : "");
    };

    const [chat, setChat] = useState({});
    const [notifications, setNotifications] = useState([]);
    const [newMessageReceived, setNewMessageReceived] = useState({});
    const [chatNotification, setChatNotification] = useState({});
    const [readNotification, setReadNotification] = useState("");

    const chatRef = useRef(null);
    chatRef.current = { activeSec, openChat, chat, notifications };

    const showChat = (selectedChat) => {
        toggle("5");
        setChat(selectedChat);
    };

    const receiveMessage = (message) => {
        if (chatRef.current.activeSec == "5") {
            if (message.contactId == chatRef.current.chat.contactId) {
                setNewMessageReceived(message);
                if (chatRef.current.openChat) return;
            }
        } else if (chatRef.current.activeSec == "1")
            setChatNotification({
                contactId: message.contactId,
                name: message.senderName,
                image: "",
                unread: 1,
            });

        let updateNotifications = chatRef.current.notifications.filter(
            (item) => item != message.contactId
        );
        updateNotifications.push(message.contactId);
        setNotifications(updateNotifications);
    };

    const notifyReading = (notification) => {
        if (chatRef.current.activeSec == "5" && notification.isSender) {
            if (notification.contactId == chatRef.current.chat.contactId && notification.isSender) {
                setReadNotification(notification.messageId);
                if (chatRef.current.openChat) return;
            }
        } else if (chatRef.current.activeSec == "1" && !notification.isSender)
            setChatNotification({ contactId: notification.contactId, unread: 0 });

        if (!notification.isSender) {
            let updateNotifications = chatRef.current.notifications.filter(
                (item) => item != notification.contactId
            );
            setNotifications(updateNotifications);
        }
    };

    useEffect(() => {
        const connection = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_HUB_URL}/chat`, {
                accessTokenFactory: () => {
                    return `${localStorage["kuimby_token"]}`;
                },
            })
            .withAutomaticReconnect()
            .build();

        connection
            .start()
            .then((result) => {
                console.log("Connected!");
                connection.on("ReceiveMessage", receiveMessage);
                connection.on("NotifyReading", notifyReading);
            })
            .catch((e) => console.log("Connection failed: ", e));
    }, []);

    return (
        <aside className={`wrapper_chats trans ${openChat}`}>
            <div className="sec_ttl_chat">
                <h5 className="ttl_chats text fnt_medium trans">
                    <span className={`btn_menu_chat ${btnMenu}`} onClick={funtMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                    <span onClick={props.noScroll}>
                        <i className="far fa-comment"></i> Chats
          </span>
                    {notifications.length > 0 && (
                        <span className="dots_notifications text fnt_bold">
                            {notifications.length}
                        </span>
                    )}
                    <span className="btn_expand" onClick={toogleOpenChat}>
                        <ion-icon name={icoOpen}></ion-icon>
                    </span>
                </h5>
            </div>
            <div className="sec_content_chats trans">

                <div className={`content_navbar_chat trans ${showNavbar}`}>
                    <nav className="navbar_chat">
                        <div
                            className={`item_navbar ${activeSec === "1" ? "sec_active" : ""}`}
                            onClick={() => {
                                toggle("1");
                                funtMenu();
                            }}
                        >
                            <i className="fas fa-comment-dots"></i>
                            <span className="name_secc">CHATS</span>
                        </div>
                        {roleName == "Recursos Humanos" && <div
                            className={`item_navbar ${activeSec === "2" ? "sec_active" : ""}`}
                            onClick={() => {
                                toggle("2");
                                funtMenu();
                            }}>
                            <i className="fas fa-users"></i>
                            <span className="name_secc">GRUPOS</span>
                        </div>}
                        <div
                            className={`item_navbar ${activeSec === "3" ? "sec_active" : ""}`}
                            onClick={() => {
                                toggle("3");
                                funtMenu();
                            }}
                        >
                            <i className="fas fa-address-book"></i>
                            <span className="name_secc">CONTACTOS</span>
                        </div>
                    </nav>
                    {/*<div*/}
                    {/*    className="myuser_chats"*/}
                    {/*    onClick={() => {*/}
                    {/*        toggle("4");*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <AvatarChats />*/}
                    {/*</div>*/}
                </div>
                <div className={`content_sec_chats trans ${showNavbar}`}>
                    {
                        {
                            1: (
                                <ListChats
                                    showChat={showChat}
                                    chatNotification={chatNotification}
                                    setNotifications={setNotifications}
                                />
                            ),
                            2: <ListGroups showChat={showChat} />,
                            3: <ListContact showChat={showChat} />,
                            4: <ProfileChat />,
                            5: (
                                <ChatConversations
                                    currentChat={chat}
                                    newMessageReceived={newMessageReceived}
                                    readNotification={readNotification}
                                    toggle={toggle}
                                    openChat={openChat}
                                />
                            ),
                        }[activeSec]
                    }
                </div>
            </div>
        </aside>
    );
}
