import { callApi, callApiFileStorage } from './service'
import swal from "sweetalert";

export const useSurveyUserProfileAnswer = {
    createSurveyUserProfileAnswer,
    updateSurveyUserProfileAnswer,
    deleteSurveyUserProfileAnswer,
    getSurveysUserProfileAnswer,
    getSurveysUserProfileAnswersByQuestionId,
    getSurveysUserProfileAnswersListByQuestionId,
    addSurveyMultimedia
};
async function createSurveyUserProfileAnswer(params) {
    return callApi('/backend/SurveyUserProfileAnswer', 'POST', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            swal({ text: result.responseMessage, icon: "error" });
            return null;
        }
    });
}
async function getSurveysUserProfileAnswer() {
    return callApi('/backend/SurveyUserProfileAnswer', 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function getSurveysUserProfileAnswersByQuestionId(idQuestion, surveyUserProfileId) {
    return callApi('/backend/SurveyUserProfileAnswer/' + idQuestion.toString() + '/' + surveyUserProfileId, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function getSurveysUserProfileAnswersListByQuestionId(idQuestion, surveyUserProfileId) {
    return callApi('/backend/SurveyUserProfileAnswers/' + idQuestion.toString() + '/' + surveyUserProfileId, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function updateSurveyUserProfileAnswer(params) {
    return callApi('/backend/SurveyUserProfileAnswer', 'PUT', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            swal({ text: result.responseMessage, icon: "error" });
            return null;
        }
    });
}
async function deleteSurveyUserProfileAnswer(params) {
    return callApi('/backend/SurveyUserProfileAnswer', 'DELETE', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function addSurveyMultimedia(params, files) {
    const formData = new FormData();
    formData.append("SurveyAnswerConfigId", params.SurveyAnswerConfigId);
    formData.append("SurveyId", params.SurveyId);
    formData.append("InputValues", params.InputValues);
    formData.append("FileImage", files);
    return callApiFileStorage('/backend/SurveyMultimedia', 'POST', formData, false).then((result) => {
        console.log('result', result)
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result?.responseMessage);
            return null;
        }
    });
}