import React, { useEffect, useState } from "react";
import { chatService } from "./../../services/chat.service";
import { Searches } from "./searches";
import { TtlsChats } from "./ttlsChats";
import { AvatarChats } from "./avatarChats";

export function ListContact({ showChat }) {
    const [contacts, setContacts] = useState([]);
    const [contactsSearch, setContactsSearch] = useState([]);

    useEffect(() => {
        chatService.getContacts().then((result) => {
            setContacts(result);
            setContactsSearch(result);
        });
    }, []);

    const handleSearch = (text) => {
        if (text) {
            text = text.toUpperCase();
            let initial = text.substring(0, 1);
            let initialFiltered = contacts.find((item) =>
                item.initial.includes(initial)
            );
            if (initialFiltered) {
                let usersFiltered = initialFiltered.users.filter((item) =>
                    item.name.toUpperCase().includes(text)
                );
                if (usersFiltered.length > 0) {
                    let contactsFiltered = [
                        {
                            initial: initialFiltered.initial,
                            users: Object.assign([], usersFiltered),
                        },
                    ];
                    setContactsSearch(contactsFiltered);
                    return;
                }
            }

            setContactsSearch([]);
        } else setContactsSearch(contacts);
    };

    const handleOpenChat = (user) => {
        const chat = {
            contactId: user.userId,
            name: user.name,
            image: user.urlImage,
            readOnly: false
        };
        showChat(chat);
    };

    return (
        <div className="sections_chat active_sec wrapper_contacts p-3">
            <TtlsChats title="Contactos" />
            <Searches placeHolder="Buscar contacto" handleSearch={handleSearch} />

            <div className="">
                {contactsSearch.map((item) => (
                    <div className="content_contacts" key={item.initial}>
                        <div className="letter_divider">
                            <span>{item.initial}</span>
                        </div>
                        {item.users.map((user) => (
                            <div className="box_item_chat " key={user.userId}>
                                <AvatarChats img={user.urlImage} />
                                <div className="info_item_chat">
                                    <div className="name_chat text fnt_book">{user.name}</div>
                                    <div className="options">
                                        <span
                                            className="opt_chat"
                                            onClick={() => handleOpenChat(user)}
                                        >
                                            <i className="fas fa-comment-dots"></i>
                                        </span>
                                        {/* <span className="opt_chat">
                     <i className="fas fa-info-circle"></i>
                    </span> */}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

