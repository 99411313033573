import React, { useState } from "react";
import { SearchSurveyScale } from "./searchSurveyScale";
import "./surveyAdmin.scss";
import { Link } from "react-router-dom";

export function AdminAnswerScales() {
  const [refresh, setRefresh] = useState(0);

  return (
    <section className="wrapper_survey_admin padd">
      <div className="mb-3">
        <Link className="link_action" to="/AdminSurvey">
          <i className="fas fa-angle-left"></i> Regresar
        </Link>
      </div>
      <div className="mb-4 divtop">
        <div className="box_ttl_opt no_reverse mb-0">
          <div>
            <h2 className="mb-0 text fnt_medium">Escalas de valor</h2>
            <h6 className="mb-0 text-muted">Administrador</h6>
          </div>
          <div className="box_actions">
           
            <Link className="btn primary" to={"/survey-scale/0"}>
              <i className="fas fa-plus"></i> Nuevo
            </Link>
          </div>
        </div>
      </div>
      <div>
        <SearchSurveyScale refresh={refresh} />
      </div>
    </section>
  );
}
