import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import { SliderHome } from "../../components/sliderHome/sliderHome";
import { BoxUserProfile } from "../../components/boxUserProfile/boxUserProfile";
import { BoxBusiness } from "../../components/boxBusiness";
import { BoxBlackboard } from "../../components/boxBlackboard";
import { BoxSchedule } from "../../components/boxSchedule";
import { backendService } from "../../services/backend.service";
import { messageService } from "../../services/message.service";

// ==ICONOS==
import IcoMessage from "../../assets/imgs/iconos/ico_message.png";
import IcoPoll from "../../assets/imgs/iconos/ico_poll.png";
import IcoDiscount from "../../assets/imgs/iconos/ico_discount.png";
import IcoRewards from "../../assets/imgs/iconos/ico_rewards.png";
import Moment from "react-moment";
import "moment/locale/es";
import { useSelector } from "react-redux";

export function Home() {
  const calendarStrings = useSelector((state) => state.calendarStrings);
  const trigger = undefined;
  const [widgets, setWidgets] = useState({
    surveyWidget: {},
    messageWidget: {},
    rewardWidget: {},
    providerWidget: [{ id: 0, logoCrls: "" }],
  });
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    backendService.widgets().then((data) => {
      if (data.surveyWidget != null) {
        let date = new Date();
        date.setDate(date.getDate() + data.surveyWidget.expirationDays);
        data.surveyWidget.expirationDate = date;
      }
      setWidgets(data);
    });

    messageService
      .getMessage(0, "desc", "pending")
      .then((data) => setMessages(data));
  }, [trigger]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2800,
    arrows: false,
    adaptiveHeight: true,
  };

  return (
    <section className="wrapper_home padd">
      <div className=" row_data_home">
        <Link to="/messages" className="link_data_home">
          <div className="item_data_home shadows">
            <div>
              <h2 className="mb-0 text fnt_medium">{messages.length}</h2>
              <h5 className="mb-0 text fnt_medium">Mensajes</h5>
            </div>
            <div className="drop_icon">
              <img src={IcoMessage} alt="Mensaje" />
            </div>
          </div>
        </Link>
        <Link to="/surveys" className="link_data_home">
          <div className="item_data_home shadows">
            <div>
              <h2 className="mb-0 text fnt_medium">
                {widgets.surveyWidget && widgets.surveyWidget.quantity}
              </h2>
              <h5 className="mb-0 text fnt_medium">
                Tu opinión
                {/*{widgets.surveyWidget && widgets.surveyWidget.quantity > 1*/}
                                {/*  ? "s"*/}
                                {/*  : ""}*/}
                            </h5>
                            <h6 className="label_data text-muted">
                                Vence{" "}
                                {widgets.surveyWidget && (
                                    <Moment calendar={calendarStrings}>
                                        {widgets.surveyWidget.expirationDate}
                                    </Moment>
                                )}
                            </h6>
                        </div>
                        <div className="drop_icon">
                            <img src={IcoPoll} alt="Encuenta" />
                        </div>
                    </div>
                </Link>
                <Link to="/rewards" className="link_data_home">
                    <div className="item_data_home shadows">
                        <div>
                            <h2 className="mb-0 text fnt_medium">
                                {widgets.rewardWidget && widgets.rewardWidget.rewardPoints}
                            </h2>
                            <h6 className="label_data text fnt_medium"> Puntos en</h6>
                            <h5 className="mb-0 text fnt_medium rewards">Recompensas</h5>
                        </div>
                        <div className="drop_icon">
                            <img src={IcoRewards} alt="Recompensas" />
                        </div>
                    </div>
                </Link>
                {/* <Link to="/discount" className="link_data_home">
                    <div className="item_data_home shadows">
                        <div className="box_company_home" >
                            <Slider {...settings}>
                                {widgets.providerWidget &&
                                    widgets.providerWidget.map((item) => (
                                        <div key={item.id}>
                                            <img
                                                src={item.logoCrls + "?v=" + Date.now()}
                                                alt="Provider"
                                            />
                                        </div>
                                    ))}
                            </Slider>
                         </div>
                        <div className="drop_icon">
                            <img src={IcoDiscount} alt="Company" />
                        </div>
                    </div>
                </Link> */}
      </div>
      <div className="row row_home mb-3">
        <div className="col-sm-12 col-lg-8 mb-1">
          <SliderHome />
        </div>
        <div className="col-sm-12 col-lg-4 mb-1 ">
          <BoxUserProfile />
        </div>
      </div>
      <div className="row row_drops">
        <div className="col-md-6 col-lg-8 mb-3">
          <BoxBusiness />
        </div>
        {/*<div className="col-md-6 col-lg-4 mb-3">*/}
        {/*<BoxBlackboard />*/}
        {/*</div>*/}
        <div className="col-md-6 col-lg-4 mb-3">
          <BoxSchedule />
        </div>
      </div>
    </section>
  );
}
