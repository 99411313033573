import React, { useEffect, useState } from "react";
import { useSurvey } from "../../services/survey.service";
import { useQuestion } from "../../services/surveyQuestion.service";
import { useAnswerConfig } from '../../services/surveyAnswerConfig.service';
import { ResponsiveBar} from '@nivo/bar'

export function SurveyChartScale(props) {
  
  const [chartData , setChartData ] = useState([]);
  const [answerList, setAnswerList] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [scaleData, setScaleData] = useState([]);
  const [keys, setKeys] = useState(['Total']);

  useEffect(() => {
    if ((props.surveyId != "" && props.surveyId) || (props.surveyQuestionId != "" && props.surveyQuestionId) ||  (props.chartGrouping != "" && props.chartGrouping)) 
      getChartData(props.surveyQuestionId);
      getQuestions(props.surveyQuestionId)
      getScales(props.surveyScaleId)
      
  }, [props]);

  useEffect(() => {
    var surveyKeys = [];
    var filterData = [];
    if(scaleData.length>0){
      if(answerList.length>0){
        questionsData.map((data)=>(
          scaleData.map((v)=>{
            filterData = answerList.filter(item => parseInt(item.answerText) == v.surveyScaleAnswerId && item.selectedAnswerConfigId == data.surveyAnswerConfigId).length;
            surveyKeys.push({'escala': v.description, 'Total': filterData, 'selectedId': data.surveyAnswerConfigId})
          })
        ))
      }
    }
    setChartData(surveyKeys)
  }, [scaleData,answerList]);

  async function getQuestions(surveyQuestionId) {
    if (surveyQuestionId != "")
      useAnswerConfig.getAnswerConfigs(surveyQuestionId).then((result) => {
          if (result) {
            setQuestionsData(result);
          }
      });
  }

  async function getScales(surveyScaleId) {
    if (surveyScaleId != "")
        useSurvey.getSurveyScaleAnswerById(surveyScaleId).then((result) => {
            if (result) {
                setScaleData(result);
            }
        });
  }

  const getChartData = async (questionId) => {
    useQuestion.getQuestionAnswerList(questionId).then((result) => {
      if (result) {
        setAnswerList(result);
      }
    });
  };

  const styles = {
    root: {
      fontFamily: "consolas, sans-serif",
      textAlign: "center",
      position: "relative",
      width: 900,
      height: 700,
    },
    totalLabel: {
      fontSize: 24,
    },
  };



  return (
    <div className="wrapper_details_survey padd">
      {   
        questionsData.map((data)=>(
          <div className="mb-4 divtop">
            <div className="box_ttl_opt mb-0">
              <div>
                <h2 className="mb-0 text fnt_medium">{data.answer}</h2>
                <div style={styles.root}>
                  <ResponsiveBar
                      data={chartData.filter(item => item.selectedId == data.surveyAnswerConfigId)}
                      keys={keys}
                      indexBy="escala"
                      margin={{ top: 60, right: 100, bottom: 100, left: 60 }}
                      padding={0.45}
                      valueScale={{ type: 'linear' }}
                      indexScale={{ type: 'band', round: true }}
                      colors={{ scheme: 'nivo' }}
                      defs={[
                          {
                              id: 'dots',
                              type: 'patternDots',
                              background: 'inherit',
                              color: '#38bcb2',
                              size: 4,
                              padding: 1,
                              stagger: true
                          },
                          {
                              id: 'lines',
                              type: 'patternLines',
                              background: 'inherit',
                              color: '#eed312',
                              rotation: -45,
                              lineWidth: 6,
                              spacing: 10
                          }
                      ]}
                    borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: -45,
                        legend: 'Grupo',
                        legendPosition: 'middle',
                        legendOffset: 50
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'total',
                        legendPosition: 'middle',
                        legendOffset: -40
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                    role="application"
                    ariaLabel="Nivo bar chart demo"
                    // barAriaLabel={function(e){return e.id+": "+e.formattedValue+" in country: "+e.indexValue}}
                  />
    
                </div>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  );
}
