import React, { useEffect, useState } from 'react'
import { DiaryCalendar } from './diaryCalendar'
import { DiarySlider } from './diarySlider'
import Moment from 'react-moment'
import 'moment/locale/es'
import './diary.scss'

export function Diary() {
	const [selectedDiary, setSelectedDiary] = useState();
	const [refresh, setRefresh] = useState(0);

	const handleOnDiarySelected = (Diary) => {
		setSelectedDiary({ DiaryId: Diary.DiaryId, Description: Diary.description, StartDate: Diary.startDate, EndDate: Diary.endDate, Date: Diary.date, WorkingDay: Diary.workingDay });
	};
	return (
		<div className="wrapper_diary padd">
			<div className="row content_ttl_diary">
				<div className="col-md-6">
					<h2 className="text fnt_medium ">Calendario</h2>
					<p>
						<span className="d-none d-sm-block"><Moment locale="es" format="DD [de] MMMM  YYYY">{Date()}</Moment></span>
					</p>
					<hr />
					<div className="box_data_calendar mb-3">
						<div className="item_data_calendar">
							<span className="drops event"></span>
							<h6>Evento general</h6>
						</div>
						<div className="item_data_calendar">
							<span className="drops birthday"></span>
							<h6>Cumpleaños</h6>
						</div>
					</div>
				</div>
				<div className="col-md-6">
					<DiarySlider />
				</div>
			</div>
			<div className="row">
				<div className="col-md-12 wrapper_calendar">
					<DiaryCalendar onSelectDiary={handleOnDiarySelected} refresh={refresh} />
				</div>
			</div>


		</div>
	);
}