import React, { useRef, useState } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { SelectUsers } from "../../components/Helpers/selectUsers";
import { AudienceUsers } from "../../components/Helpers/audienceUsers";
import { GeneralUpload } from "../../components/Helpers/generalUpload";
import { adminAudienceService } from "../../services/adminAudience.service";
import { storageService } from "../../services/storage.service";
import SearchAdminAudience from "./searchAdminAudience";
import moment from "moment";
import swal from "sweetalert";

export function AdminAudience() {
  const [templateShow, setTemplateShow] = useState("1");
  const [localAttachments, setLocalAttachments] = useState([]);
  const [images, setImages] = useState([]);
  const [localfiles, setLocalFiles] = useState([]);
  const [userSelec, setUserSelec] = useState([]);
  const gridRef = useRef();
  const horas = [1,2,3,4,5,6];

  const [audience, setAudience] = useState({
    AudienceId: 0,
    Title: "",
    Reward: "",
    ConferenceUrl: "",
    StartDate: "",
    StartTime: "",
    Duracion: 0,
    PubDate: "",
    VideoUrl: "",
  });

  const toggleTemplate = (sec) => {
    if (templateShow !== sec) setTemplateShow(sec);
  };

  async function saveAudience(item) {
    adminAudienceService.create(item, localfiles)
      .then((result) => { })
      .then((result) => {
        toggleTemplate("1");
        gridRef.current.refreshDataGrid();
      });
  }

  async function updateAudience(item) {
    adminAudienceService.update(item, localfiles)
      .then((result) => { })
      .then((result) => {
        toggleTemplate("1");
        gridRef.current.refreshDataGrid();
      });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAudience({ ...audience, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userSelec.length > 0) {
      let conferenceDate = audience.StartDate + 'T' + audience.StartTime;
      let EndDate = moment(conferenceDate).add(audience.Duracion, 'hours').format("YYYY-MM-DDTHH:mm");

      let itemSave = {
        AuditoriumId: audience.AudienceId,
        Title: audience.Title,
        Reward: audience.Reward,
        ConferenceUrl: audience.ConferenceUrl,
        ConferenceDate: conferenceDate,
        EndDate: EndDate,
        StartDate: audience.PubDate+"T00:00",
        ListUser: userSelec,
        CompanyId: process.env.REACT_APP_COMPANY,
        VideoUrl: audience.VideoUrl,
        
      };

      //console.log(itemSave);
      if( moment(EndDate).isBefore(audience.PubDate+"T00:00", 'hours')){
        swal({ text: "La fecha de publicación debe ser anterior al evento", icon: "error" });
        return;
      }

      if (parseInt(audience.AudienceId) === 0) {
        saveAudience(itemSave);
        // console.log('Guarda')
      }
      else {
        updateAudience(itemSave);
      }
    } else {
      swal({ text: "Debes elegir al menos un usuario", icon: "error" });
    }
    
    setUserSelec([]);
    setImages([]);
    setLocalFiles([]);
    setLocalAttachments([]);
  }

  const handleOnChangeAttach = (attachments) => {
    setLocalAttachments(attachments);
  };

  const handleDeleteImage = (event) => {
    storageService
      .deleteStorage({
        EntityId: images[0].entityId,
        EntityTypeId: images[0].entityTypeId,
        Sequence: images[0].sequence,
      })
      .then((result) => {
        setImages([]);
      });
    event.stopPropagation();
  };

  const handleOnChangeFile = (files) => {
    setLocalFiles(files);
  };

  const handleRowSelect = (gridItem) => {
    // console.log(gridItem);
    toggleTemplate("2");
    var duration = moment.duration(moment(gridItem.endDate).diff(gridItem.conferenceDate));
    var horas = duration.asHours();
    // console.log('horas',horas)
    setAudience({
      AudienceId: gridItem.auditoriumId,
      Title: gridItem.title,
      Reward: gridItem.reward,
      ConferenceUrl: gridItem.conferenceUrl,
      StartDate: gridItem.conferenceDate.split('T')[0],
      StartTime: gridItem.conferenceDate.split('T')[1],
      Duracion: horas,
      PubDate: gridItem.startDate.split('T')[0],
      VideoUrl: gridItem.videoUrl,
    });

    adminAudienceService
      .getAudiceImageById(gridItem.auditoriumId)
      .then((result) => {
        setImages(result.files);
      });

  };

  const handleOnNew = () => {
    let audienceNew = {
      AudienceId: 0,
      Title: "",
      Reward: "",
      ConferenceUrl: "",
      StartDate: "",
      StartTime: "",
      Duracion: 0,
      PubDate: "",
      VideoUrl: "",
    };

    setAudience(audienceNew);
    toggleTemplate("2")
    setUserSelec([]);
    setImages([]);
    setLocalFiles([]);
    setLocalAttachments([]);
  };

  return (
    <>
      <section className="wrapper_post_admin padd">
        <div className="mb-4 divtop ">
          <div className="box_ttl_opt no_reverse mb-0">
            <h2 className="mb-0 text fnt_medium">Auditorio</h2>
          </div>
          <div className="box_ttl_opt no_reverse">
            <h6 className="mb-0 text-muted">Administrador</h6>
          </div>
        </div>
        {
          {
            1: (
              <>
                <div className="mb-4 mt-4 divtop ">
                  <div className="box_ttl_opt no_reverse">
                    <h4 className="mb-0 text fnt_medium">
                      Lista de publicaciones
                    </h4>
                    <div className="box_actions ">
                      <button
                        type="button"
                        className="btn secundary mr-2"
                        onClick={handleOnNew}
                      >
                        <i className="fas fa-plus"></i> Agregar publicación
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <SearchAdminAudience
                    handleRowSelect={handleRowSelect}
                    ref={gridRef}
                  />
                </div>
              </>
            ),
            2: (
              <>
                <span className="back_go" onClick={() => toggleTemplate("1")}>
                  <i className="fas fa-chevron-left"></i> Atrás
                </span>
                <form className="card shadows p-3 mt-3" onSubmit={handleSubmit}>
                  <fieldset className="mt-4 mb-3">
                    <legend className="text fnt_medium">
                      Nuevo evento / Editar evento
                    </legend>
                    <div className="row">
                      <div className="col-sm-6 col-lg-4">
                        <div className="form-group">
                          <Label for="Title">Título de evento</Label>
                          <Input
                            required
                            type="text"
                            name="Title"
                            placeholder="Título de evento"
                            onChange={handleChange}
                            value={audience.Title}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                        <div className="form-group">
                          <Label for="ConferenceUrl">URL del evento</Label>
                          <Input
                            type="text"
                            name="ConferenceUrl"
                            placeholder="URL del evento"
                            onChange={handleChange}
                            value={audience.ConferenceUrl}
                          />
                        </div>
                      </div>
                      {/* <div className="col-sm-6 col-lg-4 mb-3">
                        <div className="form-group">
                            <Label for="ConferenceUrl">URL de grabación</Label>
                            <Input
                              type="text"
                              name="VideoUrl"
                              placeholder="URL de grabación (posterior al evento)"
                              onChange={handleChange}
                              value={audience.VideoUrl}
                            />
                          </div>
                      </div> */}
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-lg-4">
                        <div className="form-group">
                          <Label for="StartDate">Fecha de Evento</Label>
                          <Input
                            required
                            type="date"
                            className="mb-2"
                            min={moment().format("YYYY-MM-DD")}
                            onChange={handleChange}
                            name="StartDate"
                            value={audience.StartDate}
                            placeholder="Fecha de evento"
                          />
                          <Input
                            required
                            type="time"
                            onChange={handleChange}
                            className="mb-2"
                            name="StartTime"
                            value={audience.StartTime}
                            placeholder="Hora de evento"
                          />
                          <select className="form-control" name="Duracion" onChange={handleChange}
                              selectvalue={audience.Duracion} value={audience.Duracion} required={true} >
                              <option value="0">{"Duración (Horas)"}</option>
                              {
                                horas.map(item => (
                                  <option key={item} value={item}>
                                      {item}
                                  </option>
                                )
                              )}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                        <div className="form-group">
                          <Label for="PubDate">Inicio de Publicación</Label>
                          <Input
                            required
                            type="date"
                            className="mb-2"
                            onChange={handleChange}
                            name="PubDate"
                            value={audience.PubDate}
                            placeholder="Inicio de Publicación"
                          />
                        </div>
                        <div className="form-group">
                          <Label for="Reward">Recompensa</Label>
                          <Input
                            required
                            type="text"
                            name="Reward"
                            placeholder="Recompensa"
                            onChange={handleChange}
                            value={audience.Reward}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                      <div className="form-group">
                          <FormGroup>
                            {images !== null && images.length > 0 ? (
                              <div className="post_img ">
                                <img
                                  src={`data:image/jpeg;base64,${images[0].file}`}
                                  style={{ width: "150px" }}
                                />
                                <span
                                  onClick={(event) => handleDeleteImage(event)}
                                >
                                  <i className="far fa-trash-alt"></i>
                                </span>
                              </div>
                            ) : null}
                          </FormGroup>
                          {images == null || images.length === 0 ? (
                            <GeneralUpload
                              accept="image/*"
                              isMultiple="false"
                              TitleLabel={"Imagen del evento"}
                              handleOnChangeFiles={handleOnChangeFile}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <hr />
                    {audience.AudienceId > 0 ? (
                      <AudienceUsers
                        targetId={audience.AudienceId}
                        eventType="Auditorio"
                        selectUser={setUserSelec}
                      />
                    ) : (
                      <SelectUsers
                        selectUser={setUserSelec}
                        title={"Evento dirigido a:"} />
                    )}
                  </fieldset>
                  <div className="text-rigNameht mt-2 mb-2">
                    <button className="btn ml-1 mrName-1 secundary" type="button" onClick={() => toggleTemplate("1")}>
                      Regresar
                    </button>
                    <button className="btn ml-1 Namemr-1 primary" type="submit">
                      Guardar
                    </button>
                  </div>
                </form>
                <br />
                <br />
                <br />
              </>
            ),
          }[templateShow]
        }
      </section>
    </>
  );
}
