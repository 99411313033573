import React, { useState } from "react";
import { EditSurveyScale } from "./editSurveyScale";
import { Link } from "react-router-dom";

export function AdminAnswerScalesDetails(props) {
  return (
    <div className="wrapper_details_survey padd">
      <div className="mb-3">
        <Link className="link_action" to="/AdminAnswerScales">
          <i className="fas fa-angle-left"></i> Regresar
        </Link>
      </div>
      <div className="mb-4 divtop">
        <div className="box_ttl_opt mb-0">
          <div>
            <h2 className="mb-0 text fnt_medium">Escala de valor</h2>
            <h6 className="mb-0 text-muted">
              {props.match.params.id != 0 ? "Editar" : "Nueva"}
            </h6>
          </div>
        </div>
      </div>

      <EditSurveyScale surveyId={props.match.params.id} />
    </div>
  );
}
