import { callApi } from './service'
import swal from "sweetalert"

export const useSurvey = {
    createSurvey,
    updateSurvey,
    deleteSurvey,
    getSurveys,
    getSurveysById,
    getSurveysInfoById, 
    getChartData,
    getSurveyScales,
    getSurveyScalesById,
    getSurveyScaleAnswerById,
    createSurveyScale,
    updateSurveyScale,
    createScaleAnswer,
    updateScaleAnswer,
    deleteScaleAnswer,
    deleteScale
};
async function createSurvey(params) {
    return callApi('/backoffice/Survey/Create', 'POST', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "Se guardo exitosamente", icon: "success" }).then(() => window.location.href = "/details-survey/" + result.data)
        } else {
            swal({ text: "Error al guardar", icon: "error" });
        }
    });
}
async function getSurveys() {
    return callApi('/backoffice/Survey', 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}

async function getSurveysById(id) {
    return callApi('/backoffice/Survey/Get/' + id, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            swal({ text: result.responseMessage, icon: "error" }).then((value) => {
                window.location.href = '/AdminSurvey'
              });
            return null;
        }
    });
}

async function getSurveysInfoById(id) {
    return callApi('/backoffice/Survey/GetSurveyInfo/' + id, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            swal({ text: result.responseMessage, icon: "error" }).then((value) => {
                window.location.href = '/AdminSurvey'
              });;
            return null;
        }
    });
}

async function getChartData(surveyId, questionId, surveyGroupId) {
    return callApi('/backoffice/Survey/GetChartData/' + surveyId + '/' + questionId + '/' + surveyGroupId, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function updateSurvey(params) {
    return callApi('/backoffice/Survey/PutSurvey', 'PUT', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "La encuesta se guardo exitosamente", icon: "success" })
        } else {
            swal({ text: "Error al guardar encuesta", icon: "error" });
        }
    });
}
async function deleteSurvey(params) {
    return callApi('/backoffice/Survey', 'DELETE', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "La encuesta se desactivo correctamente", icon: "success" })
            return result.data;
        } else {
            swal({ text: "Error al desactivar encuesta", icon: "error" });
            return null;
        }
    });
}

async function getSurveyScales() {
    return callApi('/backoffice/SurveyScale', 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}

async function getSurveyScalesById(id) {
    return callApi('/backoffice/SurveyScale/'+id, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}

async function getSurveyScaleAnswerById(id) {
    return callApi('/backoffice/SurveyScaleAnswer/'+id, 'GET').then((result) => {
        if (result.responseCode === '00'){
            return result.data;
        }
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}

async function updateSurveyScale(params) {
    return callApi('/backoffice/SurveyScale', 'PUT', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "Se guardo exitosamente", icon: "success" })
        } else {
            swal({ text: "Error al guardar", icon: "error" });
        }
    });
}

async function createSurveyScale(params) {
    return callApi('/backoffice/SurveyScale', 'POST', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "Se guardo exitosamente", icon: "success" }).then(() => window.location.href = "/survey-scale/" + result.data.surveyScaleId)
        } else {
            swal({ text: "Error al guardar", icon: "error" });
        }
    });
}

async function updateScaleAnswer(params) {
    return callApi('/backoffice/SurveyScaleAnswer', 'PUT', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "Se guardo exitosamente", icon: "success" })
        } else {
            swal({ text: "Error al guardar", icon: "error" });
        }
    });
}

async function createScaleAnswer(params) {
    return callApi('/backoffice/SurveyScaleAnswer', 'POST', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "Se guardo exitosamente", icon: "success" }).then(() => window.location.href = "/survey-scale/" + result.data.surveyScaleId)
        } else {
            swal({ text: "Error al guardar", icon: "error" });
        }
    });
}

async function deleteScaleAnswer(params) {
    return callApi('/backoffice/SurveyScaleAnswer', 'DELETE', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "Se desactivo correctamente", icon: "success" })
            return result.data;
        } else {
            swal({ text: "Error al desactivar", icon: "error" });
            return null;
        }
    });
}

async function deleteScale(params) {
    return callApi('/backoffice/SurveyScale', 'DELETE', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "Se desactivo correctamente", icon: "success" })
            return result.data;
        } else {
            swal({ text: "Error al desactivar", icon: "error" });
            return null;
        }
    });
}