import React, { useEffect, useState } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { useSurvey } from '../../services/survey.service'
import { SelectCustom } from '../../components/Helpers/SelectCustom'

export function EditQuestionScale(props) {
	const onSave = () => {
		if (inputs.SurveyScaleAnswerId && inputs.SurveyScaleAnswerId > 0)
			useSurvey.updateScaleAnswer({ SurveyScaleAnswerId: parseInt(inputs.SurveyScaleAnswerId), SurveyScaleId: parseInt(inputs.SurveyScaleId), ScaleValue: parseInt(inputs.ScaleValue), Description: inputs.Description, AnswerOrder: parseInt(inputs.AnswerOrder) }).then((result) => {
				if (props.onSave)
					props.onSave();
			});
		else
			useSurvey.createScaleAnswer({ SurveyScaleId: parseInt(inputs.SurveyScaleId), ScaleValue: parseInt(inputs.ScaleValue), Description: inputs.Description, AnswerOrder: parseInt(inputs.AnswerOrder) }).then((result) => {
				if (props.onSave)
					props.onSave();
			});
	};
	const handleOnCancel = () => {
		if (props.onCancelEdit)
			props.onCancelEdit();
	};
	const [question, setQuestion] = useState({ SurveyScaleAnswerId: "", SurveyScaleId: props.surveyId, ScaleValue: "", Description: "", AnswerOrder: ""});
	const [refreshAnswers, setRefreshAnswers] = useState(0);
	const [showEditAnswer, setShowEditAnswer] = useState(false);
	const [selectedAnswer, setSelectedAnswer] = useState();
	const { handleSubmit, handleInputChange, inputs } = useInputForm(onSave, question);

	useEffect(() => {
		console.log('props.currentQuestion',props.currentQuestion)
		let newP = {};
		if (props.currentQuestion) {
			newP = { SurveyScaleAnswerId: props.currentQuestion.SurveyScaleAnswerId, SurveyScaleId: props.currentQuestion.SurveyScaleId, ScaleValue: props.currentQuestion.ScaleValue, Description: props.currentQuestion.Description, AnswerOrder: props.currentQuestion.AnswerOrder };
			setQuestion(newP);
		}
		else
			setQuestion({ SurveyScaleAnswerId: "", SurveyScaleId: props.surveyId, ScaleValue: "", Description: "", AnswerOrder: "" });
		setRefreshAnswers(prev => prev + 1);
		setSelectedAnswer(null);
		setShowEditAnswer(false);
	}, [props.currentQuestion]);

	return (
		<div className="content_new_question">
			<form onSubmit={handleSubmit}>
				<div className="row">
					<input type="hidden" name="SurveyScaleAnswerId" onChange={handleInputChange} value={inputs.SurveyScaleAnswerId} />
					<input type="hidden" name="SurveyScaleId" onChange={handleInputChange} value={inputs.SurveyScaleId} />
					<div className="col-12">
						<div className="form-group">
							<label for="">Respuesta</label>
							<input type="text" className="form-control" name="Description" onChange={handleInputChange} value={inputs.Description} required />
						</div>
					</div>
					<div className="col-6">
						<div className="form-group">
							<label for="">Orden</label>
							<input type="number" className="form-control" name="AnswerOrder" onChange={handleInputChange} value={inputs.AnswerOrder} required />
						</div>
					</div>
					<div className="col-6">
						<div className="form-group">
							<label for="">Valor</label>
							<input type="number" className="form-control" name="ScaleValue" onChange={handleInputChange} value={inputs.ScaleValue} required />
						</div>
					</div>
				</div>
				<div className="mt-2 text-right">
					<button className="btn secundary minimum ml-1 mr-1" type="button" onClick={handleOnCancel}>Cancelar</button>
					<button className="btn primary minimum ml-1 mr-1" type="submit">Agregar</button>
				</div>
			</form>
		</div>

	);
}   