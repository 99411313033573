import React, { useState, useEffect } from "react";
import "./surveys.scss";
import { useSurveyUserProfile } from "../../services/surveyUserProfile.service";
import { backendService } from "../../services/backend.service";

import { ListSurveys } from "./listSurveys";
import BoxSurveys from "./boxSurveys";
import PicturePoll from "../../assets/imgs/picture_poll.png";
import Moment from "react-moment";
import "moment/locale/es";
import { useSelector } from "react-redux";

export function Surveys(props) {
  const [activeAsideSurvey, setStateAsideSurvey] = useState("");

  const AsidePurchaseBox = () => {
    setStateAsideSurvey(activeAsideSurvey === "" ? " is-show" : "");
  };

  const calendarStrings = useSelector((state) => state.calendarStrings);

  const [profile, setProfileInfo] = useState();
  const [selectedSurvey, setSelectedSurvey] = useState();
  const [surveysData, setSurveysData] = useState({
    pendingSurveys: [],
    resolvedSurveys: [],
  });

  useEffect(() => {
    getUserProfile();
    getSurveys();
  }, [props.refresh]);

  const getUserProfile = () => {
    backendService.userProfile().then((data) => {
      setProfileInfo(data);
    });
  };

  const getSurveys = async () => {
    useSurveyUserProfile.getSurveysUserProfile().then((result) => {
      if (result) {
        setSurveysData(result);
      }
    });
  };

  const resetBoxSurveys = async () => await setSelectedSurvey(undefined);

  const handleOnSurveySelected = (survey) => {
    resetBoxSurveys().then(() => {
      setSelectedSurvey(survey);
    });
  };

  const [viewSurvey, setViewSurvey] = useState("d-none");
  const handleViewSurvey = () => {
    setViewSurvey(viewSurvey === "d-none" ? "" : "d-none");
  };

  return (
    <section className="wrapper_surveys padd">
      <div className="content_surveys ">
        <div className="row align-items-center mb-4">
          <div className="col">
            <h2 className="mb-0 text fnt_medium ">Tu opinión</h2>
          </div>
          <div className="col text-muted box_more_survey">
            <span className="d-none d-sm-block">
              <Moment locale="es" format="DD [de] MMMM  YYYY">
                {Date.now()}
              </Moment>
            </span>
            <span className="btn_ellipsis" onClick={AsidePurchaseBox}>
              <i className="fas fa-ellipsis-v"></i>
            </span>
          </div>
        </div>

        <div className="content_ttls">
          <div>
            <h4 className="text fnt_medium ttl mb-4">
              Gracias por atender esta encuesta
            </h4>
            <p>
              Tu opinión es muy importante para nosotros por lo que esperamos
              seas lo más honesto posible
            </p>
          </div>
          <div className="box_img_surveys">
            <img src={PicturePoll} alt="Encuestas" />
          </div>
        </div>

        <div className="row ">
          <p className="col text-muted">
            Esta encuesta es anónima y personal, lee detenidamente cada ítem, es
            muy fácil de responder, en la mayoría de las preguntas se pide que
            elijas entre varias posibilidades.
          </p>
        </div>

        <div className="row boxes_surveys">
          <div className="col mb-4">
            <div className="box_list_surveys card shadows">
              <h5 className="text fnt_medium mb-4">Encuestas disponibles</h5>
              {surveysData.pendingSurveys.length == 0 ? (
                <div className="text-center  mt-5">
                  <h6 className="">
                    Por el momento no tienes encuestas pendientes
                  </h6>
                </div>
              ) : (
                <ListSurveys
                  surveys={surveysData.pendingSurveys}
                  onSelectSurvey={handleOnSurveySelected}
                  handleViewSurvey={handleViewSurvey}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <aside className={`aside_lateral trans ${activeAsideSurvey}`}>
        <span className="btn_ellipsis_close" onClick={AsidePurchaseBox}>
          <i className="far fa-window-close"></i>
        </span>
        <div className="text-center">
          <h4 className="text fnt_medium mb-1">Recompensas</h4>
          <h6 className="text-muted">
            {
              //profile && (profile.nickname == null || profile.nickname == "") ?
              profile &&
                profile.middleName +
                  " " +
                  profile.lastName +
                  " " +
                  profile.firstName
              //: profile && profile.nickname
            }

            {/* {profile && profile.firstName} {profile && profile.middleName} {profile && profile.lastName} */}
          </h6>
          <div className="box_avatar_user">
            <span className="avatar">
              <img src={profile && profile.urlProfileImage} alt="NameUser" />
            </span>
          </div>
          {surveysData.pendingSurveys.length == 0 ? (
            <h6 className="text fnt_medium mb-1">
              Por el momento no tienes encuestas pendientes{" "}
            </h6>
          ) : (
            <div>
              <h6 className="text fnt_medium mb-0">
                {surveysData.pendingSurveys[0].title}{" "}
              </h6>
              <h6>
                Vence{" "}
                <Moment calendar={calendarStrings}>
                  {surveysData.pendingSurveys[0].endDate}
                </Moment>
              </h6>
              <h4 className="text fnt_medium c_green">
                {surveysData.pendingSurveys[0].reward} puntos
              </h4>
            </div>
          )}
        </div>

        <div className="prev_surveys">
          <h6 className="text fnt_medium mb-3 text-muted">
            Últimas encuestas realizadas
          </h6>
          {surveysData.resolvedSurveys.length == 0 ? (
            <h6 className="text fnt_medium mb-1">
              {" "}
              Por el momento no tienes encuestas realizadas
            </h6>
          ) : (
            surveysData.resolvedSurveys.map((survey) => {
              return (
                <div className="item_prev_surveys" key={survey.surveyId}>
                  <span>
                    <i className="fas fa-check-circle"></i>
                  </span>
                  <div>
                    <h6 className="mb-0 text fnt_medium">{survey.title}</h6>
                    <p>
                      <small className="text-muted">
                        <Moment calendar={calendarStrings}>
                          {survey.modifiedDate}
                        </Moment>
                      </small>
                    </p>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </aside>

      <div className={`wrapper_answering ${viewSurvey}`}>
        <div className="sec_answering">
          {selectedSurvey ? (
            <BoxSurveys
              survey={selectedSurvey}
              surveys={surveysData.pendingSurveys}
            />
          ) : (
            <div className="text-center mt-4">
              <div>
                <span className="ico_round">
                  <i className="fas fa-pencil-alt"></i>
                </span>
              </div>
              <h5 className="text-muted text fnt_medium mt-3">
                <span className="ico_left mr-1 trans">
                  <i className="fas fa-arrow-left"></i>
                </span>
                <span className="ico_up mr-2">
                  <i className="fas fa-arrow-up"></i>
                </span>
                Selecciona una encuesta para comenzar
              </h5>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
