import React, { useEffect, useState, useRef } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { SelectUsers } from "../../components/Helpers/selectUsers";
import { AudienceUsers } from "../../components/Helpers/audienceUsers";
import { useSurvey } from '../../services/survey.service'
import { SearchQuestion } from './searchQuestion';
import { EditQuestionScale } from './editQuestionScale';
import { Link } from 'react-router-dom';
import moment from 'moment';
import swal from "sweetalert";
import { SearchQuestionScale } from './searchQuestionScale';

export function EditSurveyScale(props) {
    const [userSelec, setUserSelec] = useState([]);
    const [survey, setSurvey] = useState({ SurveyScaleId: 0, Description: "", Active: true });
    const [refreshQuestions, setRefreshQuestions] = useState(0);
    const [showEditQuestion, setShowEditQuestion] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState();
    const minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

    const onSave = () => {
            if (inputs.SurveyScaleId && inputs.SurveyScaleId > 0)
                useSurvey.updateSurveyScale(inputs);
            else
                useSurvey.createSurveyScale(inputs);
    };

    const { handleSubmit, handleInputChange, inputs } = useInputForm(onSave, survey);

    useEffect(() => {
        if (props.surveyId != 0) {
            useSurvey.getSurveyScalesById(props.surveyId).then((result) => {
                setSurvey({ SurveyScaleId: result.surveyScaleId, Description: result.description, Active: result.active });
            });
        }

        setRefreshQuestions(prev => prev + 1);
        setSelectedQuestion(null);
        setShowEditQuestion(false);
    }, [props.survey]);

    const handleOnSelectQuestion = (question) => {
        setShowEditQuestion(true);
        setSelectedQuestion({SurveyScaleAnswerId: question.surveyScaleAnswerId, SurveyScaleId: question.surveyScaleId, ScaleValue: question.scaleValue, Description: question.description, AnswerOrder: question.answerOrder });
    }
    const handleOnNewQuestion = () => {
        setSelectedQuestion(null);
        setShowEditQuestion(true);
    };
    const handleOnCancelEditQuestion = () => {
        setSelectedQuestion(null);
        setShowEditQuestion(false);
    }
    const handleOnSaveEditQuestion = () => {
        setRefreshQuestions(prev => prev + 1);
        setShowEditQuestion(false);
    }

    return (
        <div className="wrapper_form_survey card shadows p-4 ">
            <form onSubmit={handleSubmit} className="mb-5">
                <fieldset >
                    <legend className="text fnt_medium">{props.surveyId != 0 ? "Editar" : "Nueva"}</legend>
                    <div className="row">
                        <div className="col-md-6 col-lg-8">
                            <input type="hidden" name="SurveyScaleId" onChange={handleInputChange} value={inputs.SurveyScaleId} />
                            <div className="form-group">
                                <label for="">Título</label>
                                <input type="text" name="Description" className="form-control" onChange={handleInputChange} value={inputs.Description} required />
                            </div>
                        </div>
                    </div>
                    <div>
                        <hr />
                        
                        <Link className="btn secundary minimum ml-1 mr-1" to="/AdminAnswerScales" >Regresar</Link>
                        <button className="btn primary minimum" type="submit">Guardar</button>
                        
                    </div>
                </fieldset>
            </form>
            {props.surveyId != 0 ?
                <div className="row">
                    <div className="col-lg-6 mb-4">
                        <fieldset >
                            <legend className="text fnt_medium">Lista de Respuestas</legend>
                            {!showEditQuestion ? <div className="mb-3"><span className="pl-2 link_action" onClick={handleOnNewQuestion}><i className="fas fa-plus"></i> Agregar</span></div> : null}

                            <SearchQuestionScale survey={{ surveyId: props.surveyId }} onSelectQuestion={handleOnSelectQuestion} refresh={refreshQuestions} />

                        </fieldset>
                    </div>
                    <div className="col-lg-6 mb-4">
                        <fieldset>
                            <legend className="text fnt_medium">Respuesta</legend>
                            {showEditQuestion ?
                                <EditQuestionScale surveyId={props.surveyId} currentQuestion={selectedQuestion} onCancelEdit={handleOnCancelEditQuestion} onSave={handleOnSaveEditQuestion} />
                                :
                                <div className="box_empty">
                                    <span><i className="fas fa-exclamation-triangle"></i></span>
                                    <h6 className="text fnt_medium">Selecciona una respuesta</h6>
                                </div>}
                        </fieldset>
                    </div>
                </div>
                : null}

        </div>
    );
}   