import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";

import { Tooltip } from "reactstrap";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

import "./header.scss";

//import LogoCompany from '../../assets/imgs/R_C.png'
import { userService } from "../../services/user.service";
import { useSelector } from "react-redux";
import { backendService } from "../../services/backend.service";
import LogoCompany from "../../assets/imgs/R_C.png";
import { ImgCache } from "../../components/CustonHooks/imgCache";

export function Header({ MyFun, activeBtn }) {
  const history = useHistory();
  const roleName = useSelector((state) => state.userSettings.roleName);

  const companyLogo = useSelector((state) => state.userSettings.companyLogo);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const togglex = () => setTooltipOpen(!tooltipOpen);

  const logout = () => {
    var style = document.getElementsByTagName("style");
    document
      .getElementsByTagName("head")[0]
      .removeChild(style[style.length - 1]);
    userService.logout().then(() => {
      history.push("/");
    });
  };

  const [notificationInfo, setNotificationInfo] = useState({
    notificationInfoData: [],
  });

  useEffect(() => {
    // const timer = setInterval(() => {
    //   getNotificationInfo();
    // }, 5000);
  }, []);

  async function getNotificationInfo() {
    backendService.GetNotificationData().then((result) => {
      if (result) {
        setNotificationInfo({ notificationInfoData: result });
      }
    });
  }

  async function updateLogin(item) {
    backendService.updateNoticationLogin(item);
  }

  return (
    <header className="wrapper_header trans">
      <div className="box_brand">
        <span className={`btn_menu ${activeBtn}`} onClick={MyFun}>
          <span></span>
          <span></span>
          <span></span>
        </span>
        <NavLink to={roleName == "Operaciones" ? "/homeOps/1" : "/home"}>
          <ImgCache src={companyLogo ? companyLogo : LogoCompany} alt="Brand" />
        </NavLink>
      </div>
      <div className="content_header">
        <div className="box_options">
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle
              className="btn_notifications"
              tag="span"
              aria-expanded={dropdownOpen}
            >
              <span className="icon_bell">
                <i className="far fa-bell"></i>
                <span className="bullet">
                  {" "}
                  {notificationInfo.notificationInfoData.length}{" "}
                </span>
              </span>
            </DropdownToggle>
            <DropdownMenu right>
              <div className="content_box_notif">
                <div className="notif_ttl text fnt_medium">Notificaciones</div>

                {notificationInfo.notificationInfoData.length > 0 ? (
                  notificationInfo.notificationInfoData.map((item) => (
                    <a
                      className="item_notification"
                      href={
                        item.linkNotification == "/api/Login"
                          ? ""
                          : item.linkNotification
                      }
                      onClick={
                        item.linkNotification == "/api/Login"
                          ? (e) => {
                              e.preventDefault();
                              updateLogin(
                                item.companyEventTypeNotificationMessage
                              );
                            }
                          : toggle
                      }
                    >
                      <span>
                        {item.companyEventTypeNotificationMessage}{" "}
                        {item.notificationMessage}{" "}
                      </span>
                    </a>
                  ))
                ) : (
                  <div className="notif_empty">
                    <i className="far fa-bell-slash"></i>
                    <span>No hay nuevas notificaciones</span>
                  </div>
                )}
              </div>
            </DropdownMenu>
          </Dropdown>
          <span className="item_options" onClick={logout}>
            <span id="TooltipClose">
              <ion-icon name="log-out-outline"></ion-icon>
              <Tooltip
                placement="bottom"
                isOpen={tooltipOpen}
                target="TooltipClose"
                toggle={togglex}
              >
                Cerrar sesión
              </Tooltip>
            </span>
          </span>
        </div>
      </div>
    </header>
  );
}
